import React, { useState } from "react";
import SearchIcon from "../../img/Search-icon.svg";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ placeholder }: any) => {
	const navigate = useNavigate();
	const [searchStr, setSearchStr] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (searchStr.length !== 0) {
			if (searchStr !== " ") {
				let replaceSpaces = searchStr.replace(/\s/g, "+");
				navigate(`/search?q=${replaceSpaces}`);
			} else {
				navigate(`/search?q=`);
			}
		}
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<input
				data-testid="search"
				style={{ marginRight: "3px" }}
				type="text"
				placeholder={placeholder}
				value={searchStr}
				onChange={(e) => setSearchStr(e.target.value)}
				maxLength={180}
			/>

			<button type="submit" style={{ background: "#005da4", border: "none" }}>
				<img height="15" src={SearchIcon} alt="Go" />
			</button>
		</form>
	);
};

export default SearchBar;
