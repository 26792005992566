import { AccessPass } from "types/payment";
import styles from "./Payment.module.css";
import { motion } from "framer-motion";

type Props = {
	selectedValue: AccessPass | undefined;
	setSubscription: (v: AccessPass) => void;
	subscriptions: AccessPass[] | undefined;
	loading: boolean;
};
function SubscriptionSelect({ selectedValue, setSubscription, subscriptions, loading }: Props) {
	const handleChange = (e: any) => {
		let id = e.target.value;
		let selected = subscriptions?.find((sub) => sub.passID === id)!;
		setSubscription(selected);
	};
	return (
		<>
			{loading || !subscriptions ? (
				<LoadingPlaceholder />
			) : (
				<div className={styles.subscriptionSelectContainer}>
					{subscriptions.map((sub) => {
						let selected = selectedValue?.passID === sub.passID;
						return (
							<label className={styles.subscriptionItem}>
								<input type="radio" name={sub.name} value={sub.passID} checked={selected} onChange={handleChange} />
								<span className={`${styles.passName} ${selected ? styles.selected : ""}`}>{sub.name}</span>
								<span className={`${styles.price} ${selected ? styles.selected : ""}`}>{`$${sub.amount / 100}`}</span>
							</label>
						);
					})}
				</div>
			)}
		</>
	);
}

function LoadingPlaceholder() {
	return (
		<div className={styles.subscriptionLoader}>
			{[...Array(2)].map((e, i) => (
				<motion.div
					key={i}
					className={styles.placeholderBlockContainer}
					animate={{ opacity: 0.25 }}
					transition={{
						yoyo: Infinity,
						duration: 0.75,
						delay: i * 0.2,
					}}
				>
					<div className={styles.block}></div>
					<div className={styles.block}></div>
				</motion.div>
			))}
		</div>
	);
}

export default SubscriptionSelect;
