import React, { useEffect } from "react";
import styles from "./PrivacyPolicy.module.css";
import ReactGA from "react-ga4";
export default function PrivacyPolicy() {
	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	return (
		<div className={styles.privacyPolicy}>
			<h1>Privacy Policy</h1>
		</div>
	);
}
