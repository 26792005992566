import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeaturedCarousel from "./featuredCarousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchBar from "../../../components/filters/SearchBar";
import GetFacilities from "../../../components/data/getFacilities/GetFacilities";
import GetBroadcasts from "../../../components/data/getBroadcasts/GetBroadcasts";
import NearbyIcon from "../../../img/nearby-link-icon.svg";
import { Helmet } from "react-helmet";
import styles from "./Home.module.css";
import ReactGA from "react-ga4";

const Home = () => {
	const [liveBroadcasts, setLiveBroadcasts] = useState([]);
	const [upcomingBroadcasts, setUpcomingBroadcasts] = useState([]);
	const [archivedBroadcasts, setArchivedBroadcasts] = useState([]);

	useEffect(() => {
		// GA page view
		ReactGA.send({ hitType: "page", page: window.location.pathname, title: "Home" });
	}, []);

	return (
		<div className={styles.home}>
			<Helmet>
				<title>MuscoVision</title>
				<meta name="description" content="Musco Vision" />
			</Helmet>
			<section className={styles.section1} style={{ textAlign: "center", paddingTop: "75px" }}>
				<h1>FEATURED BROADCASTS</h1>

				{liveBroadcasts.length > 0 ? (
					<FeaturedCarousel broadcasts={liveBroadcasts} isLoading={false} />
				) : upcomingBroadcasts.length > 0 ? (
					<FeaturedCarousel broadcasts={upcomingBroadcasts} isLoading={false} />
				) : archivedBroadcasts.length > 0 ? (
					<FeaturedCarousel broadcasts={archivedBroadcasts} isLoading={false} />
				) : (
					<FeaturedCarousel broadcasts={[]} isLoading={true} />
				)}
			</section>
			<section className={styles.find}>
				<h1 className={styles.findTitle}>FIND A GAME</h1>
				<div className={styles.blueHorizontalRule}></div>
				<p className={styles.findDesc}>Search for a game or facility.</p>
				<div className={styles.searchBar}>
					<SearchBar placeholder="SEARCH" />
				</div>

				<Link to="/nearby" className={styles.gamesNearMeLink}>
					<img style={{ marginRight: ".5rem" }} src={NearbyIcon} alt="Games Near Me Icon" />
					FIND GAMES NEAR ME
				</Link>
			</section>

			<section className={styles.section3}>
				<div className={styles.expandableList}>
					<GetFacilities searchQuery="" isLoading={false} numOfItemsDefault={6} numOfItemsToIncrement={6} />
				</div>

				<div className={styles.expandableList}>
					{/* live broadcasts */}
					<GetBroadcasts
						facilityID={""}
						updateParent={(x) => setLiveBroadcasts(x)}
						isLoading={false}
						viewerStatus={"streaming"}
					/>
				</div>
				<div className={styles.expandableList}>
					{/* upcoming broadcasts */}
					<GetBroadcasts
						facilityID={""}
						updateParent={(x) => setUpcomingBroadcasts(x)}
						isLoading={false}
						viewerStatus={"scheduled"}
					/>
				</div>
				<div className={styles.expandableList}>
					{/* archived broadcasts */}
					<GetBroadcasts
						facilityID={""}
						updateParent={(x) => setArchivedBroadcasts(x)}
						isLoading={false}
						viewerStatus={"archived"}
					/>
				</div>
			</section>
		</div>
	);
};

export default Home;
