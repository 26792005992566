// General
export const SET_SIDEDRAWER_STATE = "SET_SIDEDRAWER_STATE";
export const TOGGLE_FILTERMENU = "TOGGLE_FILTERMENU";
export const TOGGLE_SIDEDRAWER = "TOGGLE_SIDEDRAWER";
export const SET_SCREEN_WIDTH = "SET_SCREEN_WIDTH";

// Modal
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_CURRENT_MODAL = "SET_CURRENT_MODAL";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR_DATA = "SET_ERROR_DATA";
export const NEW_TAG_ADDED = "NEW_TAG_ADDED";

// User
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_PAYMENT_SUCCEEDED = "SET_PAYMENT_SUCCEEDED";
