import React, { useState, useEffect } from "react";

import styles from "./Auth.module.css";

import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal, setLoading, closeModal } from "../../../redux/actions/modal";

import ReactGA from "react-ga4";
import { confirmSignUp, autoSignIn, resendSignUpCode } from "aws-amplify/auth";

interface Props {
	email: string;
	password: string;
	backToLogin: () => void;
}

export default function ConfirmAccount({ email, password, backToLogin }: Props) {
	const dispatch = useDispatch();

	const [errorMessage, setErrorMessage] = useState<string>(null!);
	const [code, setCode] = useState("");
	const [codeResent, setCodeResent] = useState(false);
	const [timer, setTimer] = useState(0);
	useEffect(() => {
		ReactGA.event({
			category: "Modal",
			action: "Confirm Account Modal",
		});
		setTimer(30);
	}, []);
	useEffect(() => {
		// Timer countdown
		let interval: NodeJS.Timeout | null = null;
		if (timer > 0) {
			interval = setInterval(() => {
				setTimer((currentTimer) => currentTimer - 1);
			}, 1000);
		} else if (interval) {
			clearInterval(interval);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [timer]);
	const handleConfirmation = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setErrorMessage(null!);

		if (code === "") {
			setErrorMessage("Please enter confirmation code");
		} else {
			dispatch(setLoading({ isLoading: true, message: "Loading..." }));
			await confirmSignUp({ username: email, confirmationCode: code })
				.then(async () => {
					// window.location.reload();
					//    If account is confirmed successful, attempt to sign the user in automatically
					//    with the username and password they entered on the previous modal.
					//    If that fails, redirect them to the login modal
					await autoSignIn()
						.then((res) => {
							// dispatch(toggleLogin(true));
							// dispatch(setLoading({ isLoading: false, message: "" }));
							// dispatch(closeModal());
							window.location.reload();
						})
						.catch((err) => {
							dispatch(setLoading({ isLoading: false, message: "" }));
							backToLogin();
						});
				})
				.catch(() => {
					setErrorMessage("Verification code is invalid");
					dispatch(setLoading({ isLoading: false, message: "" }));
				});
		}
	};

	const resendConfirmationCode = async () => {
		setCodeResent(false);

		setErrorMessage(null!);
		setCode("");
		setTimer(30);
		dispatch(setLoading({ isLoading: true, message: "Sending Confirmation Code" }));
		try {
			await resendSignUpCode({ username: email });

			setCodeResent(true);
		} catch (error: any) {
			setErrorMessage(error.message);
		} finally {
			dispatch(setLoading({ isLoading: false, message: "" }));
		}
	};

	return (
		<div className={styles.formWrapper}>
			<form onSubmit={handleConfirmation}>
				<h3
					style={{
						color: "black",
						marginBottom: "1.5rem",
						textAlign: "center",
					}}
				>
					CONFIRM YOUR ACCOUNT
				</h3>
				<p className={styles.generalMessage}>
					We have sent a confirmation code to: <span style={{ color: "rgb(0, 93, 164)" }}>{email}</span>
					<br />
					<br />
					Please enter your code into the box below to verify your account.
					<br />
					<br />
				</p>
				<div className={styles.inputGroup}>
					<label className={styles.label}>CONFIRMATION CODE</label>
					<input type="text" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Confirmation Code" />
					{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}
				</div>

				<button type="submit" className={styles.submitBtn} style={{ marginBottom: "1rem" }}>
					CONTINUE
				</button>
				{codeResent && <p className={styles.successMessageSmall}>Confirmation code has been sent.</p>}
				<span className={styles.resendInfo}>
					{timer === 0 ? (
						<>
							Didn't receive an email? Try checking your junk/spam folders OR{" "}
							<button type="button" onClick={resendConfirmationCode}>
								resend code
							</button>
						</>
					) : (
						<>You can request another code in {timer} seconds</>
					)}
				</span>
			</form>
		</div>
	);
}
