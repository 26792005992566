import React, { useState, useRef } from "react";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import FacebookIcon from "../../../img/Fb_color.svg";
import XTwitterIcon from "../../../img/x-twitter-square.svg";
import MailIcon from "../../../img/Mail.svg";

import styles from "./ShareLink.module.css";
export default function Share() {
	const [isCopied, setIsCopied] = useState(false);
	const textToCopy = useRef();
	const copyLink = () => {
		textToCopy.current.select();
		document.execCommand("copy");
		setIsCopied(true);
	};

	return (
		<div className={styles.share}>
			<p style={{ color: "black", marginBottom: ".5rem" }}>Share with:</p>
			<div className={styles.row}>
				<FacebookShareButton className={styles.socialbtn} url={window.location.href} title={"Facebook"}>
					<img src={FacebookIcon} height={35} />
				</FacebookShareButton>
				<TwitterShareButton className={styles.socialbtn} url={window.location.href} title={"X (formerly Twitter)"}>
					<img src={XTwitterIcon} height={35} />
				</TwitterShareButton>
				<EmailShareButton className={styles.socialbtn} url={window.location.href} title={"Email"}>
					<img src={MailIcon} height={35} />
				</EmailShareButton>
			</div>
			<div className={styles.copyLinkRow} onClick={copyLink}>
				<input ref={textToCopy} className={styles.copyLinkTxt} type="text" value={window.location.href} />
				<button className={styles.copyLinkBtn}>{isCopied ? "COPIED" : "COPY"}</button>
			</div>
		</div>
	);
}
