import styles from "./Styles.module.css";
import DownArrow from "../../../../img/down-arrow-blue.svg";
import Moment from "react-moment";
import * as moment from "moment";
import Spinner from "../../../general/spinner/Spinner";

const DesktopList = ({ data, numOfColumns, showMoreData, showLessData, isGettingMore, moreToShow }) => {
	const renderButton = () => {
		if (data.length >= numOfColumns) {
			return (
				<div className={styles.showDataBtnRow}>
					<div className={styles.horizontalLines}></div>
					{data.length > 6 ? (
						<button className={styles.showLessBtn} onClick={() => showLessData()}>
							<img height="6px" src={DownArrow} alt="Down Arrow" />
							SHOW LESS
						</button>
					) : null}

					{moreToShow ? (
						<button className={styles.showMoreBtn} onClick={() => showMoreData()}>
							SHOW MORE
							<img height="6px" src={DownArrow} alt="Down Arrow" />
						</button>
					) : null}

					<div className={styles.horizontalLines}></div>
				</div>
			);
		}
	};

	const getEndTime = (date, increase) => {
		var startdate = moment(date);
		var returned_endate = moment(startdate).add(increase, "seconds");
		return returned_endate;
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				{data &&
					data.map((item, index) => {
						let endTime = getEndTime(item.date, item.expected_duration);
						console.log(item.medium_image);
						return (
							<a
								key={item.id}
								href={`/broadcast/${item.id}`}
								className={styles.contentBlock}
								style={numOfColumns === 4 ? { width: "calc(25% - 30px)" } : { width: "calc(33% - 30px)" }}
							>
								<div className={styles.contentBlockImg} style={{ backgroundImage: `url(${item.medium_image})` }}></div>

								<div className={styles.broadcastInfo}>
									<div>
										<h6 data-testid="title" style={{ color: "white" }}>
											{item.title}
										</h6>
										<p className={styles.broadcastSubheading}>{item.site_title}</p>
										<p className={styles.startsAtText}>
											TIME:{" "}
											<span className={styles.broadcastTime}>
												<Moment format="hh:mm A" date={item.date} />
											</span>
											{" - "}
											<span className={styles.broadcastTime}>
												<Moment format="hh:mm A" date={endTime} />
											</span>
										</p>

										<p className={styles.broadcastDate}>
											<Moment format="MMM DD YYYY" date={item.date} />
										</p>
									</div>
									{/* <MoreActions /> */}
								</div>
							</a>
						);
					})}
			</div>
			{isGettingMore ? <Spinner color="255, 255, 255" /> : renderButton()}
		</>
	);
};

export default DesktopList;
