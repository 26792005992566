import React from "react";
import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

interface Broadcast {
	site_id: string; // or number, depending on your data structure
	id: string; // or number
	medium_image: string;
	title: string;
}

interface MobileListProps {
	broadcasts: Broadcast[];
	isLoading: boolean; // Assuming isLoading is a boolean, adjust if necessary
}

const MobileList: React.FC<MobileListProps> = ({ broadcasts, isLoading }) => {
	const navigate = useNavigate();
	var settings = {
		dots: false,
		infinite: false,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		draggable: true,
	};

	const onBroadcastClick = (siteId: string, broadcastId: string) => {
		navigate(`/broadcast/${broadcastId}`);
	};

	return (
		<div className="mobileMediaList">
			<Slider {...settings}>
				{broadcasts.length !== 0
					? broadcasts.map((item, index) => (
							<div key={index} onClick={() => onBroadcastClick(item.site_id, item.id)}>
								{/* <img
									width="100%"
									style={{ maxHeight: "250px", backgroundColor: "rgb(154, 154, 154)" }}
									src={item.medium_image}
								/> */}
								<div className="contentBlockImg" style={{ backgroundImage: `url(${item.medium_image})` }}></div>
								<h6 className="itemTitle" style={{ color: "white" }}>
									{item.title}
								</h6>
							</div>
					  ))
					: null}
			</Slider>
		</div>
	);
};

export default MobileList;
