import arbiterApi from "api/arbiter/axiosConfig";

type Props = {
	searchQuery: string;
	perPage: number;
	page: number;
};

const fetchFacilities = ({ searchQuery, perPage, page }: Props) => {
	return new Promise(async (resolve, reject) => {
		try {
			let res = await arbiterApi.get(`/pub/facility?per_page=${perPage}&page=${page}&search=${searchQuery}`);
			resolve(res.data);
		} catch (err: any) {
			console.log(err);
			reject();
		}
	});
};

export default fetchFacilities;
