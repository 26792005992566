import React, { useState, useEffect } from "react";
import styles from "../AccountDetails.module.css";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModal } from "../../../../../redux/actions/modal";

export default function Subscription({ user, refreshData }: any) {
	const dispatch = useDispatch();
	// const paymentSucceeded = useSelector((state: any) => state.user.paymentSucceeded);
	const [subscriptionEnd, setSubscriptionEnd] = useState<Date>();

	useEffect(() => {
		// Check if subscription exists and if its expired.
		let passExpiration = new Date(user.subscription.pass.passEnd ?? 0);
		console.log(passExpiration);
		if (passExpiration > new Date()) {
			setSubscriptionEnd(passExpiration);
		}
	}, []);

	return (
		<div className={styles.accountItem}>
			<h3>SUBSCRIPTION</h3>
			<br />
			{subscriptionEnd ? (
				<p>{`Your subscription will end on ${format(new Date(subscriptionEnd), "MMMM dd, yyyy")}.`}</p>
			) : (
				<div>
					<p>You do not currently have a valid subscription.</p>

					<button type="button" className={styles.subscribeBtn} onClick={() => dispatch(setCurrentModal("payment"))}>
						BUY A PASS
					</button>
				</div>
			)}
		</div>
	);
}
