import React from "react";

import styles from "./TagLoadingIndicatorBlocks.module.css";

import { motion } from "framer-motion";

let TagLoadingIndicatorBlocks = () => {
	return (
		<div className={styles.container}>
			{[...Array(4)].map((e, i) => (
				<motion.div
					key={i}
					className={styles.placeholderBlock}
					animate={{ opacity: 0.25 }}
					transition={{
						yoyo: Infinity,

						duration: 0.75,

						delay: i * 0.2,
					}}
				></motion.div>
			))}
		</div>
	);
};

export default TagLoadingIndicatorBlocks;
