import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentModal, setLoading, closeModal } from "../../../redux/actions/modal";
import { signIn, resendSignUpCode } from "aws-amplify/auth";
import styles from "./Auth.module.css";
import ReactGA from "react-ga4";
import logo from "../../../img/MuscoVision_logo-blue-grey.svg";
import ConfirmAccount from "./ConfirmAccount";

type Errors = {
	email: string;
	password: string;
};

export default function Login() {
	const dispatch = useDispatch();
	const [renderConfirmationForm, setRenderConfirmationForm] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>(null!);
	const [successMessage, setSuccessMessage] = useState<string>(null!);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [fieldErrors, setFieldErrors] = useState<Errors>({
		email: null!,
		password: null!,
	});

	useEffect(() => {
		ReactGA.event({
			category: "Modal",
			action: "Login Modal",
		});
	}, []);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setErrorMessage(null!);
		setFieldErrors({
			email: null!,
			password: null!,
		});

		let res = validateFields();
		if (res.success) {
			handleSignIn();
		} else {
			setFieldErrors(res.errors);
		}
	};
	const validateFields = () => {
		const errors: Errors = {
			email: null!,
			password: null!,
		};
		let errorFound = false;
		if (email === "") {
			errorFound = true;
			errors.email = "Please enter your email address";
		} else {
			// Validate the email with a regex
			const res =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!res.test(String(email).toLowerCase())) {
				errorFound = true;
				errors.email = "Email is invalid";
			}
		}

		if (password === "") {
			errorFound = true;
			errors.password = "Please enter your password";
		}

		if (!errorFound) {
			return {
				success: true,
				errors: null!,
			};
		} else {
			return {
				success: false,
				errors: errors,
			};
		}
	};

	const handleSignIn = async () => {
		dispatch(setLoading({ isLoading: true, message: "" }));
		try {
			const res = await signIn({ username: email, password: password });

			console.log(res);
			switch (res.nextStep.signInStep) {
				case "CONFIRM_SIGN_UP":
					await resendConfirmationCode();
					break;
				case "DONE":
					console.log("reload");
					window.location.reload();
					break;
			}
		} catch (err: any) {
			console.log(err);

			setErrorMessage(err.message);
		} finally {
			dispatch(setLoading({ isLoading: false, message: "" }));
		}
	};
	async function resendConfirmationCode() {
		try {
			await resendSignUpCode({ username: email });
			setRenderConfirmationForm(true);
		} catch (error) {
			setErrorMessage("An unexpected error occurred. Please try again later or contact support.");
		}
	}

	return (
		<>
			{renderConfirmationForm ? (
				<ConfirmAccount email={email} password={password} backToLogin={() => setRenderConfirmationForm(false)} />
			) : (
				<div className={styles.formWrapper}>
					<img alt="logo" style={{ marginBottom: "3rem" }} src={logo} />
					<h3 style={{ color: "black", marginBottom: "1.5rem", textAlign: "center" }}>SIGN IN TO YOUR ACCOUNT</h3>

					{successMessage ? <span className={styles.successMessage}>{successMessage}</span> : null}
					{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}
					<form className={styles.loginForm} onSubmit={handleSubmit}>
						<div className={styles.inputGroup}>
							<label className={styles.label}>EMAIL</label>
							<input
								autoFocus
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="EMAIL"
								autoComplete="on"
							/>
							{fieldErrors.email !== null ? <span className={styles.errorMessage}>{fieldErrors.email}</span> : null}
						</div>
						<div className={styles.inputGroup}>
							<label className={styles.label}>PASSWORD</label>
							<input
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								type="password"
								placeholder="PASSWORD"
							/>
							{fieldErrors.password !== null ? (
								<span className={styles.errorMessage}>{fieldErrors.password}</span>
							) : null}
						</div>
						<button
							type="button"
							onClick={() => dispatch(setCurrentModal("reset_password"))}
							className={styles.forgotPasswordBtn}
						>
							Forgot password?
						</button>

						<button className={styles.submitBtn} type="submit">
							LOG IN
						</button>
					</form>

					<p style={{ color: "black", marginTop: "3rem" }}>Don't have an account?</p>
					<button className={styles.switchToSignUpBtn} onClick={() => dispatch(setCurrentModal("register"))}>
						SIGN UP
					</button>
				</div>
			)}
		</>
	);
}
