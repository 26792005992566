import React from "react";

import Tag from "./Tag";

import styles from "../TagSidebar.module.css";

import TagLoadingIndicatorBlocks from "./tagLoadingIndicatorBlocks/TagLoadingIndicatorBlocks";
import { TagDefinition } from "types/tagging";

type PropsTypes = {
	tagClicked: (tag: TagDefinition) => void;
	tags: TagDefinition[];
	tagsSelected: TagDefinition[];
	visibility: "public" | "private";
	loading: boolean;
};
export default function TagList({ tagClicked, tags, tagsSelected, visibility, loading }: PropsTypes) {
	const RenderTags = () => {
		if (tags.length > 0) {
			return (
				<>
					{tags.map((tag) => (
						<Tag
							tagsSelected={tagsSelected}
							key={tag.name}
							tagName={tag.name}
							visibility={visibility}
							tagClicked={() => tagClicked(tag)}
						/>
					))}
				</>
			);
		} else {
			const message =
				visibility === "private"
					? "There are no tags to display here. Add a tag by clicking the + button above."
					: "There was a problem loading tags.";

			return <p className={styles.noDataMessage}>{message}</p>;
		}
	};

	return <div className={styles.tagList}>{loading ? <TagLoadingIndicatorBlocks /> : <RenderTags />}</div>;
}
