import { fetchAuthSession, signOut } from "aws-amplify/auth";
import axios, { AxiosRequestConfig } from "axios";
import { ACCESS_TOKEN, ID_TOKEN } from "constants/auth";
import Cookies from "js-cookie";

const mvApi = axios.create({
	baseURL: "https://royia0wuq3.execute-api.us-east-2.amazonaws.com/prod/web",
});

mvApi.interceptors.request.use(
	async function (config: AxiosRequestConfig): Promise<any> {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {};

		// Initialize headers if undefined
		config.headers ||= {};
		config.headers.Authorization = accessToken;

		return config;
	},

	function (error) {
		console.log(error);
		return Promise.reject(error);
	}
);
// let reattemptedRequest = false;
mvApi.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response, message } = error;

		if (response) {
			const { status, config } = response;
			switch (status) {
				case 401: // Unauthorized
					// return logout();
					return console.log("logout");

				default:
					console.error(response);
					// logout();
					throw error;
			}
		} else if (message === "Network Error") {
			console.error("Network error:", error);
			throw error;
		} else {
			console.error("Unknown error:", error, message);
			throw error;
		}
	}
);

const logout = async () => {
	try {
		await signOut();
	} catch (err: any) {
		console.error(err);
	} finally {
		window.location.href = "/";
	}
};

export default mvApi;
