import React, { useState } from "react";
import styles from "./Error.module.css";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../redux/actions/modal";
//todo: set error daTA TO NULL

export default function Error({ errorData }) {
	const { message } = errorData;
	const dispatch = useDispatch();
	return (
		<div className={styles.error}>
			<h4 className={styles.errorTitle}>{message}</h4>
			{/* <p className={styles.errorDesc}>{message}</p> */}

			<p className={styles.errorContactMessage}>
				Please try again later. If you continue seeing this error, contact our
				support team at{" "}
				<a href="mailto:support@muscovision.com">support@muscovision.com</a>
			</p>
			<button className={styles.okBtn} onClick={() => dispatch(closeModal())}>
				OK
			</button>
		</div>
	);
}
