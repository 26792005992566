import React, { useRef, useState, useEffect } from "react";
import styles from "./Header.module.css";
import SearchIcon from "../../../img/Search-icon.svg";
import { useNavigate } from "react-router-dom";

export default function HeaderSearch() {
	const navigate = useNavigate();
	const [searchBarOpen, setSearchBarOpen] = useState(true);
	const [searchStr, setSearchStr] = useState("");

	const node = useRef<any>();
	const input = useRef();

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (searchStr.length !== 0) {
			if (searchStr !== " ") {
				let replaceSpaces = searchStr.replace(/\s/g, "+");
				navigate(`/search?q=${replaceSpaces}`);
			} else {
				navigate(`/search?q=`);
			}
		}
	};

	return (
		<div tabIndex={3} ref={node} className={styles.headerSearch}>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<input
					style={
						searchBarOpen
							? { width: "350px", opacity: 1, marginLeft: "-18px" }
							: { width: "0px", opacity: 0, marginLeft: "0", paddingLeft: 0 }
					}
					// ref={(input) => input && input.focus()}
					type="text"
					placeholder="SEARCH FOR A GAME OR FACILITY"
					value={searchStr}
					onChange={(e) => setSearchStr(e.target.value)}
					maxLength={180}
					data-testid="searchInputNav"
				/>
				<button type="submit" data-testid="navSearch">
					<div className={styles.headerSearchIcon}>
						<img src={SearchIcon} alt="Search Icon" />
					</div>
				</button>
			</form>
		</div>
	);
}
