import { useEffect, useState } from "react";
import styles from "./Facility.module.css";
import { useNavigate } from "react-router";
import ReactGA from "react-ga4";
import GetBroadcasts from "../../../components/data/getBroadcasts/GetBroadcasts";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
const Facility = () => {
	const navigate = useNavigate();
	const [siteData, setSiteData] = useState<any>({ data: {}, isLoading: true });
	const [error, setError] = useState<boolean>(false);
	const { siteId } = useParams();

	//GA page view
	useEffect(() => {
		// ReactGA.send({ hitType: "page", page: window.location.pathname, title: "Facility" });
	}, []);

	useEffect(() => {
		getSiteData(siteId);
	}, []);

	const getSiteData = async (siteId: any) => {
		// Get currently live games at this facility
		await fetch(`https://arbiter.musco.io/pub/facility?id=${siteId}`)
			.then((response) => response.text())
			.then((result) => {
				const data = JSON.parse(result);
				setSiteData({ data: data, isLoading: false });
			})
			.catch((err) => {
				setError(true);
			});
	};

	if (error) {
		navigate("/page-not-found");
	}

	return (
		<div className={styles.facility}>
			<section
				data-testid="header"
				className={styles.facilityImg}
				style={siteData.data ? { backgroundImage: `url(${siteData.data.large_image})` } : {}}
			></section>
			<section className={styles.facilityHeader}>{siteData.data ? <h1>{siteData.data.title}</h1> : null}</section>
			<section className={styles.contentSection}>
				<div className={styles.expandableList}>
					{/* live broadcasts */}
					<GetBroadcasts
						facilityID={siteId!}
						updateParent={() => null}
						isLoading={false}
						viewerStatus={"streaming"}
						hideTitle={false}
						filters={null}
					/>
				</div>
				<div className={styles.expandableList}>
					{/* upcoming broadcasts */}
					<GetBroadcasts
						facilityID={siteId!}
						updateParent={() => null}
						isLoading={false}
						viewerStatus={"scheduled"}
						hideTitle={false}
						filters={null}
					/>
				</div>
				<div className={styles.expandableList}>
					{/* archived broadcasts */}
					<GetBroadcasts
						facilityID={siteId!}
						updateParent={() => null}
						isLoading={false}
						viewerStatus={"archived"}
						hideTitle={false}
						filters={null}
					/>
				</div>
			</section>
		</div>
	);
};

export default Facility;
