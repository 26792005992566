import arbiterApi from "api/arbiter/axiosConfig";

type Props = {
	filters: any;
	facilityID: string;
	cursor: string;
	perPage: number;
	viewerStatus: string;


}
const fetchBroadcasts = ({filters, facilityID, cursor, perPage, viewerStatus}:Props) => {
	let startDate = "";
	let endDate = "";
	let sports = "";
	let searchQuery = "";
	let sort = viewerStatus === "archived" ? "desc" : "asc";

	if (filters !== null && filters !== undefined) {
		startDate = filters.dates.startDate;
		endDate = filters.dates.endDate;
		sports = filters.sports;
		searchQuery = filters.searchQuery;
	}

	return new Promise(async (resolve, reject) => {
		try {
			let res = await arbiterApi.get(`/broadcast?sort_by=date&sort=${sort}&site_id=${facilityID}&search=${searchQuery}&before=${endDate}&after=${startDate}&sport_type=${sports}&cursor=${cursor}&per_page=${perPage}&status=${viewerStatus}`);
			resolve(res.data);
		} catch(err: any) {
			reject();
		}
	
	});
};

export default fetchBroadcasts;
