import { useEffect, useState } from "react";

import styles from "./TagSidebar.module.css";

import TagList from "./tagList/TagList";

import AddIcon from "../../../../../img/add.svg";

import TagIconWhite from "../../../../../img/icon-tag-white.svg";

import CloseBtnIcon from "../../../../../img/X-symbol-black.svg";

import AddTagModal from "./addTagModal";
import useAuth from "contexts/auth.context";

import arbiterApi from "api/arbiter/axiosConfig";
import { TagDefinition } from "types/tagging";
export default function TagSidebar({
	closeModal,
	isOpen,
	tagClicked,
	broadcastData,
	tagsSelected,
	onCancel,
	onApply,
	addingTagInstance,

	reloadData,
	setDataReloaded,
}: any) {
	const { user } = useAuth();
	const [publicTags, setPublicTags] = useState<TagDefinition[]>([]);
	const [privateTags, setPrivateTags] = useState<TagDefinition[]>([]);
	const [tagsLoading, setTagsLoading] = useState<boolean>(true);
	const [addTagModalOpen, setAddTagModalOpen] = useState(false);
	const [getTagsError, setGetTagsError] = useState<boolean>(false);
	// const [privateTagsError, setPrivateTagsError] = useState<boolean>(false);
	useEffect(() => {
		getTagDefinitions();
		// getPrivateTagDefinitions();
	}, []);

	useEffect(() => {
		if (reloadData) {
			getTagDefinitions();
			setDataReloaded();
		}
	}, [reloadData]);

	let getTagDefinitions = async () => {
		setTagsLoading(true);
		setGetTagsError(false);
		const sportId = broadcastData.section_id;
		try {
			const res: any = await arbiterApi.get(`/tags/defs?sport=${sportId}&user_id=${user?.userID}`);

			let tags = res.data;

			setPublicTags(
				tags.public.map((tag: string) => {
					return { visibility: "public", name: tag };
				})
			);
			setPrivateTags(
				tags.private.map((tag: string) => {
					return { visibility: "private", name: tag };
				})
			);
		} catch (err: any) {
			setGetTagsError(true);
		}

		setTagsLoading(false);
	};

	return (
		<div className={`${styles.tagSidebar} ${isOpen ? styles.open : ""}`}>
			{isOpen ? (
				<button className={styles.closeBtn} onClick={closeModal}>
					<img height="12px" src={CloseBtnIcon} alt="Close Button" />
				</button>
			) : null}

			<div className={styles.tagSections}>
				<div className={styles.gameTagsSection}>
					<div className={styles.gameTagsHeader}>
						<div className={styles.tagIcon}>
							<img height="12px" src={TagIconWhite} alt="Tag Icon" />
						</div>

						<h3>
							GAME TAGS{" "}
							<div className={styles.tooltipToggle}>
								?<span className={styles.tooltipBottom}>'Game Tags' enable you to add preset public tags.</span>
							</div>
						</h3>
					</div>
					<div className={styles.tagContent}>
						<TagList
							tagsSelected={tagsSelected}
							tags={publicTags}
							tagClicked={(tagData: any) => tagClicked(tagData)}
							visibility="public"
							loading={tagsLoading}
						/>
					</div>
				</div>

				<div className={styles.myTagsSection}>
					<div className={styles.myTagsHeader}>
						<h3>
							MY TAGS{" "}
							<div className={styles.tooltipToggle}>
								?
								<span className={styles.tooltipTop}>
									'My Tags' enable you to add your team or player tag to a highlight clip
								</span>
							</div>
						</h3>

						<button className={styles.addBtn} onClick={() => setAddTagModalOpen(true)}>
							<img height="15px" src={AddIcon} alt="Add Button" />
						</button>
					</div>
					<div className={styles.tagContent}>
						<TagList
							tagsSelected={tagsSelected}
							tags={privateTags}
							tagClicked={(tagData: any) => tagClicked(tagData)}
							visibility="private"
							loading={tagsLoading}
						/>
					</div>
				</div>
			</div>

			<div className={`${styles.actions} ${tagsSelected.length > 0 ? styles.open : ""}`}>
				<button className={styles.cancel} disabled={tagsSelected === null} onClick={onCancel}>
					Cancel
				</button>

				<button className={styles.apply} disabled={tagsSelected === null || addingTagInstance} onClick={onApply}>
					{addingTagInstance ? "Saving..." : "Apply"}
				</button>
			</div>
			{addTagModalOpen ? (
				<AddTagModal
					close={() => setAddTagModalOpen(false)}
					setNewTag={(tagName: string) => setPrivateTags([{ visibility: "private", name: tagName }, ...privateTags])}
					sportId={broadcastData.section_id}
				/>
			) : null}
		</div>
	);
}
