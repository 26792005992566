import React, { useEffect } from "react";
import styles from "./NotFoundPage.module.css";
import SearchBar from "../../../components/filters/SearchBar";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import NearbyIcon from "../../../img//nearby-link-icon.svg";
const NotFoundPage = () => {
	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<section className={styles.find}>
			<h1 className={styles.heading}>PAGE NOT FOUND</h1>
			<br />
			<p className={styles.subheading}>We couldn't find the page you were looking for.</p>

			<p>Try searching for a broadcast or facility.</p>
			<div className={styles.searchBar}>
				<SearchBar placeholder="SEARCH" />
			</div>

			<Link to="/nearby" className={styles.gamesNearMeLink}>
				<img style={{ marginRight: ".5rem" }} src={NearbyIcon} alt="Games Near Me Icon" />
				FIND GAMES NEAR ME
			</Link>
		</section>
	);
};

export default NotFoundPage;
