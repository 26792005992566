import { fetchAuthSession, signOut } from "aws-amplify/auth";
import axios, { AxiosRequestConfig } from "axios";
import { ACCESS_TOKEN, ID_TOKEN } from "constants/auth";
import Cookies from "js-cookie";
// import { ACCESS_TOKEN, REFRESH_TOKEN, SESSION_ID } from "constants/auth";
// import Cookies from "js-cookie";
// import { fetchAccessToken } from "./auth";

const arbiterApi = axios.create({
	// baseURL: "https://arbiter.musco.io/pub",
	baseURL: "https://arbiter-staging.musco.io/pub",
});

arbiterApi.interceptors.request.use(
	async function (config: AxiosRequestConfig): Promise<any> {
		const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};

		// Initialize headers if undefined
		config.headers ||= {};
		config.headers.Authorization = accessToken;

		return config;
	},

	function (error) {
		console.log(error);
		return Promise.reject(error);
	}
);

arbiterApi.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response, message } = error;

		if (response) {
			const { status, config } = response;
			switch (status) {
				case 401: // Unauthorized
					try {
						// Attempt to refresh the token
						const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};
						if (idToken && accessToken) {
							return arbiterApi(config);
						} else {
							return logout();
						}
					} catch (err) {
						return logout();
					}

				default:
					console.error(response);

					throw error;
			}
		} else if (message === "Network Error") {
			console.error("Network error:", error);
			throw error;
		} else {
			console.error("Unknown error:", error, message);
			throw error;
		}
	}
);

const logout = async () => {
	try {
		await signOut();
	} catch (err: any) {
		console.error(err);
	} finally {
		window.location.href = "/";
	}
};

export default arbiterApi;
