import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import rightArrow from "../../../../../img/slide-right-arrow.svg";
import leftArrow from "../../../../../img/slide-left-arrow.svg";
import styles from "./Styles.module.css";

export default function Carroussel(props) {
	const table = props.cards.map((element, index) => {
		return { ...element, onClick: () => setGoToSlide(index) };
	});

	const [offsetRadius, setOffsetRadius] = useState(1);
	const [showArrows, setShowArrows] = useState(false);
	const [goToSlide, setGoToSlide] = useState(null);
	const [cards] = useState(table);

	useEffect(() => {
		setOffsetRadius(props.offset);
		setShowArrows(props.showArrows);
	}, [props.offset, props.showArrows]);

	return (
		<div
			style={{
				width: props.width,
				height: props.height,
				margin: props.margin,
				display: "flex",
				alignItems: "center",
			}}
		>
			<button className={styles.arrow} onClick={() => setGoToSlide(goToSlide - 1)}>
				<img src={leftArrow} />
			</button>
			<Carousel
				slides={cards}
				goToSlide={goToSlide}
				offsetRadius={offsetRadius}
				showNavigation={showArrows}
				animationConfig={config.gentle}
			/>

			<button className={styles.arrow} onClick={() => setGoToSlide(goToSlide + 1)}>
				<img src={rightArrow} />
			</button>
		</div>
	);
}
