import styles from "./Modal.module.css";
import Share from "./shareLink/ShareLink";
import Error from "./error/Error";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setCurrentModal } from "../../redux/actions/modal";
import { motion } from "framer-motion";
import Spinner from "../general/spinner/Spinner";
import CloseIcon from "../../img/X-symbol-black.svg";
import Login from "./auth/Login";
import Register from "./auth/Register";
import ConfirmAccount from "./auth/ConfirmAccount";
import ResetPassword from "./auth/ResetPassword";
import Payment from "./auth/payment";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import useAuth from "contexts/auth.context";

const Modal = () => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();
	const modalOpen = useSelector((state: any) => state.modal.modalOpen);
	const loading = useSelector((state: any) => state.modal.loading);
	const currentModal = useSelector((state: any) => state.modal.currentModal);
	const loadingMessage = useSelector((state: any) => state.modal.loadingMessage);
	const errorData = useSelector((state: any) => state.modal.errorData);
	const dispatch = useDispatch();
	console.log(currentModal);
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const modal = queryParams.get("modal");
		console.log(modal);
		if (modal) dispatch(setCurrentModal(modal));
	}, []);

	return (
		<>
			{modalOpen ? (
				<div className={styles.modalWrapper}>
					<motion.div
						initial={{ scale: 0.75, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{
							type: "spring",
							stiffness: 240,
							damping: 20,
						}}
						className={styles.modal}
					>
						{loading ? (
							<div className={styles.loadingOverlay}>
								<div className={styles.loadingContainer}>
									<Spinner color="0, 93, 164" />
									<span>{loadingMessage}</span>
								</div>
							</div>
						) : null}
						<button data-testid="closeModal" className={styles.closeBtn} onClick={() => dispatch(closeModal())}>
							<img src={CloseIcon} alt="Close Modal" />
						</button>
						{currentModal === "share" ? (
							<Share />
						) : currentModal === "error" ? (
							<Error errorData={errorData} />
						) : currentModal === "login" && !isAuthenticated ? (
							<Login />
						) : currentModal === "register" ? (
							<Register />
						) : currentModal === "reset_password" ? (
							<ResetPassword />
						) : currentModal === "payment" && isAuthenticated ? (
							<Payment />
						) : null}
					</motion.div>
				</div>
			) : null}
		</>
	);
};
export default Modal;
