import styles from "./Overview.module.css";

import { useState, useEffect } from "react";

import TagInstanceTile from "./TagInstanceTile";

import Spinner from "../../../../components/general/spinner/Spinner";
import arbiterApi from "api/arbiter/axiosConfig";
import { TagDefinition } from "types/tagging";
import useAuth from "contexts/auth.context";
export default function TagInstancesList({ tagsToFilter }: any) {
	const { user } = useAuth();
	const [broadcasts, setBroadcasts] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		// if (tagsToFilter.length === 0) {
		// 	setBroadcasts([]);
		// } else {
		handleFiltersChanged();
		// }
	}, [tagsToFilter]);
	const handleFiltersChanged = async () => {
		setIsLoading(true);
		// Fetch instances of selected tag definitions

		let tagDefinitionNames = tagsToFilter.map((tag: TagDefinition) => tag);

		const getTagInstances = async () => {
			try {
				console.log(tagDefinitionNames);
				let res = await arbiterApi.get(`/tags?${tagDefinitionNames.length > 0 ? `&name=${tagDefinitionNames}` : ""}`);

				return res.data.results;
			} catch (err: any) {
				// Todo: setError
				return;
			} finally {
				setIsLoading(false);
			}
		};

		const instances = await getTagInstances();

		let broadcastIds: string[] = [];
		instances?.forEach((item: any) => {
			if (!broadcastIds.includes(item.broadcast_id)) {
				broadcastIds.push(item.broadcast_id);
			}
		});

		const getBroadcasts = async () => {
			try {
				let res: any = [];
				const requests = broadcastIds.map((id: string) => arbiterApi.get(`/broadcast?id=${id}`));
				const responses = await Promise.all(requests);

				responses.forEach((response) => {
					if (response.data.length > 0) {
						res.push(response.data[0]);
					}
				});

				return res;
			} catch (e) {
				console.error(e);
				return;
			}
		};
		let broadcasts: any = await getBroadcasts();
		console.log(broadcasts);
		let broadcastsWithTags: any = [];

		broadcasts.forEach((broadcast: any) => {
			let broadcastsTagInstances: any = [];
			instances.forEach((instance: any) => {
				if (instance.broadcast_id === broadcast.id) {
					broadcastsTagInstances.push(instance);
				}
			});

			broadcastsWithTags.push({ ...broadcast, tagInstances: broadcastsTagInstances });
		});
		console.log(broadcastsWithTags);
		setBroadcasts(broadcastsWithTags);
		setIsLoading(false);
	};

	const renderTagInstanceTiles = broadcasts.map((broadcast: any) => <TagInstanceTile broadcast={broadcast} />);

	return (
		<>
			{isLoading ? (
				<div className={styles.spinnerWrapper}>
					<Spinner color="255, 255, 255" />
				</div>
			) : (
				<>
					{broadcasts.length > 0 ? (
						<div className={styles.tagList}>{renderTagInstanceTiles}</div>
					) : (
						<h6 className={styles.noDataMessage}>No results match your filter criteria.</h6>
					)}
				</>
			)}
		</>
	);
}
