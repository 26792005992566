import { useState, useEffect } from "react";

import styles from "./TagFilters.module.css";

import useAuth from "contexts/auth.context";
import arbiterApi from "api/arbiter/axiosConfig";
import TagToFilterPill from "./TagToFilterPill";
import TagOptionPill from "./TagOptionPill";
import { TagDefinition } from "types/tagging";

const TagFilters = ({ setTagsToFilter, tagsToFilter }: any) => {
	const { user } = useAuth();
	// const [privateTagsLoading, setPrivateTagsLoading] = useState(true);
	// const [publicTagsLoading, setPublicTagsLoading] = useState(true);
	const [privateTagDefs, setPrivateTagDefs] = useState<string[]>([]);
	const [publicTagDefs, setPublicTagDefs] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [filterModalOpen, setFilterModalOpen] = useState(false);

	useEffect(() => {
		// getPrivateTagDefinitions();
		getTagDefinitions();
	}, []);

	let getTagDefinitions = async () => {
		try {
			const res = await arbiterApi.get(`/tags/defs?user_id=${user?.userID}`);
			console.log(res);
			setPublicTagDefs(Object.values(res.data.public).flat() as string[]);
			setPrivateTagDefs(res.data.private);
		} catch (err: any) {
			// TODO
			console.error(err);
		}
	};

	const handleRemove = (tag: string) => {
		const newState = tagsToFilter.filter((item: string) => item !== tag);
		setTagsToFilter(newState);
	};

	let renderTagsToFilter = (tags: TagDefinition[]) => {
		let tagsToReturn = tags.map((item: any) => (
			<TagToFilterPill remove={(tag: string) => handleRemove(tag)} tag={item} />
		));

		return tagsToReturn;
	};

	return (
		<div className={styles.filtersContainer}>
			<p className={styles.tagFilterLabel}>FILTER BY TAG:</p>

			<div className={styles.tagFilterRow}>
				{loading ? "..." : tagsToFilter.length === 0 ? null : renderTagsToFilter(tagsToFilter)}
				<button onClick={() => setFilterModalOpen(true)} className={styles.addTagBtn}>
					Add Tag +
				</button>
			</div>

			{filterModalOpen && (
				<FilterModal
					close={setFilterModalOpen}
					tagsToFilter={tagsToFilter}
					setTagsToFilter={setTagsToFilter}
					privateTagDefs={privateTagDefs}
					publicTagDefs={publicTagDefs}
				/>
			)}
		</div>
	);
};

const FilterModal = ({ close, tagsToFilter, setTagsToFilter, privateTagDefs, publicTagDefs }: any) => {
	const [newTagsToFilter, setNewTagsToFilter] = useState(tagsToFilter);

	const handleChange = (newTagState: { active: boolean; tag: any }) => {
		let newState;

		if (newTagState.active) {
			// Add the tag by creating a new array that includes all items from tagsToFilter plus the new tag
			newState = [...newTagsToFilter, newTagState.tag];
		} else {
			// Remove the tag by filtering the array, also creating a new array instance
			newState = newTagsToFilter.filter((item: string) => item !== newTagState.tag);
		}
		console.log(newState);
		setNewTagsToFilter(newState);
	};

	const renderTagOptions = (tags: string[]) => {
		console.log(tags);
		let tagsToReturn = tags.map((item: string) => (
			<TagOptionPill tag={item} tagsToFilter={newTagsToFilter} handleChange={handleChange} />
		));

		return tagsToReturn;
	};

	return (
		<div className={styles.filterModalWrapper}>
			<div className={styles.filterModal}>
				<h3>Filter Tags</h3>
				<div className={styles.tagOptionsContainer}>
					<p className={styles.tagOptionsLabel}>MY TAGS</p>
					{privateTagDefs.length > 0 ? (
						<div className={styles.tagFilterRow}>{renderTagOptions(privateTagDefs)}</div>
					) : (
						<p style={{ color: "grey" }}>No tags to show</p>
					)}
				</div>
				<div className={styles.tagOptionsContainer}>
					<p className={styles.tagOptionsLabel}>GAME TAGS</p>
					{publicTagDefs.length > 0 ? (
						<div className={styles.tagFilterRow}>{renderTagOptions(publicTagDefs)}</div>
					) : (
						<p style={{ color: "grey" }}>No tags to show</p>
					)}
				</div>

				<div className={styles.buttonGroup}>
					<button
						className={styles.save}
						type="button"
						onClick={() => {
							setTagsToFilter(newTagsToFilter);
							close();
						}}
					>
						Save
					</button>

					<button
						className={styles.cancel}
						type="button"
						onClick={() => {
							close();
						}}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default TagFilters;
