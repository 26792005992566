import React, { useState } from "react";
import DurationSelector from "./DurationSelector";
import arbiterApi from "api/arbiter/axiosConfig";
import ClipViewer from "./ClipViewer";

type Props = {
	tagId: string;
};
export default function ClipTag({ tagId }: Props) {
	const [selectedDuration, setSelectedDuration] = useState<number>(5);
	const [url, setUrl] = useState<string>("");

	const generateClip = async () => {
		try {
			const res: any = await arbiterApi.get(`/tags/clips?tag_id=${tagId}&duration=${selectedDuration}`);
			setUrl(res.data.url);
		} catch (err: any) {
			console.log(err);
		}
	};
	return (
		<div>
			<p style={{ color: "grey" }}>
				The generated clip will begin at the timestamp of this tag. Please select a duration.
			</p>
			<br></br>
			<DurationSelector selectedDuration={selectedDuration} onChange={setSelectedDuration} />
			<br></br>
			<button onClick={generateClip}>Generate clip</button>
			<br></br>
			<br></br>
			{url && <ClipViewer src={url} />}
		</div>
	);
}
