import { useEffect } from "react";

// Styles
import "./App.css";

// Utilities

import ScrollToTop from "./components/utils/ScrollToTop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setScreenWidth } from "./redux/actions/general";
import ReactGA from "react-ga4";
import PrivateRoute from "./containers/private/PrivateRoute";
import PublicOnlyRoute from "./containers/public/PublicOnlyRoute";

// Public routes
import Home from "./containers/public/home/Home";
import Search from "./containers/public/search/Search";
import Facilities from "./containers/public/facilities/Facilities";
import Broadcast from "./containers/public/broadcast/Broadcast";
import Facility from "./containers/public/facility/Facility";
import Nearby from "./containers/public/nearby/Nearby";
import PrivacyPolicy from "./containers/public/privacyPolicy/PrivacyPolicy";
import NotFoundPage from "./containers/public/notFoundPage/NotFoundPage";

// Protected routes
import HomeProtected from "./containers/private/home/Home";

import MyAccount from "./containers/private/account";

// Components
import Header from "./components/layout/header/Header";
import SideDrawer from "./components/layout/sideDrawer/SideDrawer";
import Backdrop from "./components/layout/backdrop/Backdrop";
import Footer from "./components/layout/footer/Footer";
import Modal from "./components/modal/Modal";
import MobileNav from "./components/layout/mobileNav/MobileNav";

// Context
import useAuth from "contexts/auth.context";

import Spinner from "components/general/spinner/Spinner";

const App = () => {
	const { isAuthenticated, loading } = useAuth();
	const sideDrawerOpen = useSelector((state: any) => state.general.sideDrawerOpen);
	const dispatch = useDispatch();

	useEffect(() => {
		// Start listening for window resize
		window.addEventListener("resize", updateWindowDimensions);
		ReactGA.initialize("G-D91V6KBM2P");
		// Remove event listener on unmount
		return () => {
			window.removeEventListener("resize", () => {});
		};
	}, []);

	// Save screen width to state
	const updateWindowDimensions = () => {
		dispatch(setScreenWidth(window.innerWidth));
	};

	return (
		<>
			{loading ? (
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
					<Spinner color="0, 93, 164" />
				</div>
			) : (
				<Router>
					<ScrollToTop>
						<Header />
						<SideDrawer />
						<MobileNav />
						<Backdrop />
						<Modal />
						<main className={sideDrawerOpen ? "blur" : ""}>
							<Routes>
								<Route
									path="/account"
									element={
										<PrivateRoute>
											<MyAccount />
										</PrivateRoute>
									}
								/>

								<Route path="/nearby" Component={Nearby} />
								<Route path="/privacy-policy" Component={PrivacyPolicy} />
								<Route path="/facility/:siteId" Component={Facility} />
								<Route path="/broadcast/:broadcastId" Component={Broadcast} />
								<Route path="/search" Component={Search} />
								<Route path="/facilities" Component={Facilities} />
								<Route path="/" Component={!isAuthenticated ? Home : HomeProtected} />
								<Route path="/error/:error?" Component={NotFoundPage} />
								<Route path="*" Component={NotFoundPage} />
							</Routes>
						</main>
						<Footer />
					</ScrollToTop>
				</Router>
			)}
		</>
	);
};

export default App;
