import React, { useState, useEffect } from "react";
import styles from "./TagActionsModal.module.css";
import sportTypes from "constants/sportTypes";

import arbiterApi from "api/arbiter/axiosConfig";
import useAuth from "contexts/auth.context";
import { TagInstance } from "types/tagging";
import TabSelector from "./TabSelector";
import ShareTag from "./shareTag/ShareTag";
import ClipTag from "./clipTag";

interface Props {
	tag: TagInstance;
	close: () => void;
}

const AddTag: React.FC<Props> = ({ tag, close }) => {
	const [tabSelected, setTabSelected] = useState<string>("clip");
	return (
		<div className={styles.modalWrapper}>
			<div className={styles.modal}>
				<h3 style={{ marginBottom: "2rem" }}>#{tag.name}</h3>

				<TabSelector tabSelected={tabSelected} onChange={setTabSelected} />
				<div style={{ marginTop: "2rem" }}>
					{tabSelected === "share" ? (
						<ShareTag timestamp={tag.timestamp} />
					) : tabSelected === "clip" ? (
						<ClipTag tagId={tag.tag_id!} />
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AddTag;
