import React from "react";
import styles from "./Payment.module.css";
import SuccessAnimation from "./successAnimation";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as moment from "moment";
import { addDays, format } from "date-fns";

export default function PaymentConfirmation({ selectedSubscription }: any) {
	let location = useLocation();

	let broadcastToReturnTo = location.state?.broadcastToReturnTo;

	const linkProps = broadcastToReturnTo
		? { pathname: `/broadcast/${broadcastToReturnTo}`, state: { awaitingPassUpdate: true } }
		: { pathname: "/" };

	const getPassExpiration = () => {
		const currentDate = new Date();
		const expirationDate = addDays(currentDate, selectedSubscription.passLength);
		return format(expirationDate, "MMMM dd, yyyy");
	};

	return (
		<div className={styles.paymentConfirmation}>
			<SuccessAnimation />
			<h3>
				THANK YOU FOR
				<br />
				YOUR PURCHASE!
			</h3>
			<button onClick={() => window.location.reload()} className={styles.submitBtn}>
				START WATCHING
			</button>
			{selectedSubscription ? (
				<p className={styles.subscriptionMessage} style={{ marginTop: "35px" }}>
					<span>NOTE: </span>
					{`Your subscription will end on ${getPassExpiration()}.`}
				</p>
			) : null}
			<p className={styles.infoText}>
				We have received your payment information and are processing your payment. You will receive a confirmation email
				at the address you provided.
			</p>
		</div>
	);
}
