import React, { useState } from "react";
import styles from "../AccountDetails.module.css";
import { updatePassword } from "aws-amplify/auth";

interface PasswordProps {
	user: any; // Replace 'any' with the actual type of your user object
	refreshData: () => void;
}

const Password: React.FC<PasswordProps> = ({ user, refreshData }) => {
	const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false);
	const [oldPassword, setOldPassword] = useState<string>("");
	const [newPassword, setNewPassword] = useState<string>("");
	const [newConfirmPW, setNewConfirmPW] = useState<string>("");
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<boolean>(false);

	const submit = async () => {
		//validate
		if (oldPassword.length === 0) {
			setError("Please enter your current password.");
		} else if (newPassword.length === 0) {
			setError("Please enter your new password.");
		} else if (newConfirmPW !== newPassword) {
			setError("Passwords do not match.");
		} else {
			try {
				await updatePassword({ oldPassword, newPassword });
				setSuccess(true);
				setError(null);
				setEditModeEnabled(false);
				refreshData();
			} catch (err: any) {
				switch (err.message) {
					default:
						setError(
							"We are unable to update your password. Please make sure that your current password is correct and your new password is at least 6 characters."
						);
				}
			}
		}
	};

	return (
		<div className={styles.accountItem}>
			<div className={styles.row}>
				<div className={styles.left}>
					<h3>PASSWORD</h3>

					{editModeEnabled ? (
						<>
							<br />
							<label>CURRENT PASSWORD</label>
							<input
								style={{ marginBottom: "10px" }}
								className={styles.textInput}
								type="password"
								onChange={(e) => setOldPassword(e.target.value)}
							/>

							<label>CREATE PASSWORD</label>
							<input
								style={{ marginBottom: "10px" }}
								className={styles.textInput}
								type="password"
								onChange={(e) => setNewPassword(e.target.value)}
							/>

							<label>CONFIRM NEW PASSWORD</label>
							<input className={styles.textInput} type="password" onChange={(e) => setNewConfirmPW(e.target.value)} />
						</>
					) : null}
				</div>
				{!editModeEnabled ? (
					<button className={styles.edit} type="button" onClick={() => setEditModeEnabled(true)}>
						Edit
					</button>
				) : null}
			</div>
			{error ? <p style={{ color: "red", marginTop: "10px" }}>{error}</p> : null}
			{success ? <p style={{ color: "green", marginTop: "10px" }}>Your password has been updated.</p> : null}

			{editModeEnabled ? (
				<div className={styles.buttonGroup}>
					<button className={styles.save} type="button" onClick={() => submit()}>
						Save
					</button>
					<button
						className={styles.cancel}
						type="button"
						onClick={() => {
							setEditModeEnabled(false);
							setError(null);
						}}
					>
						Cancel
					</button>
				</div>
			) : null}
		</div>
	);
};

export default Password;
