import React from "react";
import styles from "./MobileNav.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSideDrawerState } from "../../../redux/actions/general";
import { signOut } from "aws-amplify/auth";
import useAuth from "contexts/auth.context";
import { setCurrentModal } from "../../../redux/actions/modal";

const MobileNav: React.FC = () => {
	const { isAuthenticated } = useAuth();
	const sideDrawerOpen = useSelector((state: any) => state.general.sideDrawerOpen);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div className={styles.mobileNav} data-testid="mobileNav">
			<NavLink
				to="/"
				className={({ isActive }) => (isActive ? styles.navItemActive : styles.navItem)}
				onClick={() => {
					dispatch(setSideDrawerState(false));
				}}
			>
				<svg width="20px" height="20px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<g id="noun_Home_1433449">
						<g id="Group" transform="translate(0 0.4134346)">
							<path
								d="M0.32738 8.43405L11.3542 0.164035C11.6776 -0.0438263 11.9924 -0.065268 12.3535 0.164035L23.3804 8.43405C23.5841 8.5885 23.7077 8.84175 23.7077 9.09737L23.7077 22.8807C23.7077 23.3137 23.3137 23.7077 22.8807 23.7077L15.4376 23.7077C15.0046 23.7077 14.6106 23.3137 14.6106 22.8807L14.6106 16.5405L9.09716 16.5405L9.09716 22.8807C9.09711 23.3137 8.70318 23.7077 8.27015 23.7077L0.827034 23.7077C0.39401 23.7077 7.57784e-05 23.3137 2.06442e-05 22.8807L2.06442e-05 9.09737C-0.00130258 8.84175 0.123687 8.5885 0.32738 8.43405ZM22.0537 9.51087L11.8539 1.86111L1.65405 9.51087L1.65405 22.0537L7.44314 22.0537L7.44314 15.7135C7.44319 15.2805 7.83713 14.8866 8.27015 14.8865L15.4376 14.8865C15.8706 14.8866 16.2646 15.2805 16.2646 15.7135L16.2646 22.0537L22.0537 22.0537L22.0537 9.51087Z"
								transform="translate(-1.529619E-05 0.09982331)"
								id="Shape"
								fill="#979797"
								fillRule="evenodd"
								stroke="none"
							/>
						</g>
					</g>
				</svg>
				<span className={styles.navItemText}>HOME</span>
			</NavLink>
			<NavLink
				to="/nearby"
				className={({ isActive }) => (isActive ? styles.navItemActive : styles.navItem)}
				onClick={() => {
					dispatch(setSideDrawerState(false));
				}}
			>
				<svg width="20px" height="20px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12.5549 21.9969C12.5775 21.999 12.6001 22 12.6222 22C12.9149 22 13.1783 21.8254 13.2918 21.5558L21.9428 1.00964C22.0578 0.736432 21.9962 0.423166 21.7872 0.214151C21.649 0.0765191 21.4657 0 21.2715 0C21.175 0 21.0815 0.0195149 20.9916 0.0575177L0.445118 8.70828C0.151875 8.83153 -0.0253033 9.12734 0.00294256 9.44368C0.0311884 9.76003 0.259209 10.0183 0.5694 10.0877L9.63015 12.1013C9.7647 12.1311 9.86998 12.2359 9.89977 12.3709L11.9124 21.431C11.9817 21.7412 12.2406 21.9682 12.5549 21.9969ZM17.9792 3.63712C18.0159 3.62137 18.055 3.61371 18.0933 3.61371C18.17 3.61371 18.2449 3.64351 18.3015 3.70012C18.3858 3.7844 18.4109 3.91211 18.3645 4.02235L13.1388 16.4333C13.0924 16.5431 12.9852 16.6133 12.8677 16.6133C12.8592 16.6133 12.8502 16.6129 12.8413 16.612C12.7136 16.601 12.6084 16.5082 12.5803 16.383L11.3978 11.0617C11.3471 10.834 11.1671 10.6543 10.9402 10.6037L5.61791 9.42116C5.49276 9.39306 5.40038 9.2875 5.38889 9.16022C5.3774 9.03252 5.44976 8.91205 5.56768 8.86268L17.9792 3.63712Z"
						id="Fill-4"
						fill="#979797"
						fillRule="evenodd"
						stroke="none"
					/>
				</svg>
				<span className={styles.navItemText}>NEARBY</span>
			</NavLink>
			<NavLink
				to="/search"
				className={({ isActive }) => (isActive ? styles.navItemActive : styles.navItem)}
				onClick={() => {
					dispatch(setSideDrawerState(false));
				}}
			>
				<svg width="20px" height="20px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<g id="Search-icon">
						<g id="Group">
							<path
								d="M20.6293 19.119L15.2931 13.7828C17.9015 10.4312 17.6654 5.58305 14.5847 2.50234C11.2482 -0.834112 5.83879 -0.834112 2.50234 2.50234C-0.834112 5.83879 -0.834112 11.2482 2.50234 14.5847C5.58305 17.6654 10.4312 17.9015 13.7828 15.2931L19.119 20.6293C19.5266 21.0369 20.2088 21.0429 20.6259 20.6259C21.0458 20.2059 21.0445 19.5342 20.6293 19.119ZM13.0744 4.01263C15.5767 6.51497 15.5767 10.5721 13.0744 13.0744C10.5721 15.5767 6.51497 15.5767 4.01263 13.0744C1.51029 10.5721 1.51029 6.51497 4.01263 4.01263C6.51497 1.51029 10.5721 1.51029 13.0744 4.01263Z"
								transform="translate(0.1331182 0.1329432)"
								id="Shape"
								fill="#979797"
								fillRule="evenodd"
								stroke="none"
							/>
						</g>
					</g>
				</svg>
				<span className={styles.navItemText}>SEARCH</span>
			</NavLink>
			{isAuthenticated ? (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<button
					style={{ background: "transparent", border: "none" }}
					className={styles.navItem}
					onClick={async () => {
						try {
							await signOut();
						} catch (error) {
							console.log("error signing out: ", error);
						} finally {
							window.location.href = "/";
						}
					}}
				>
					<svg width="26px" height="20px" viewBox="0 0 33 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<g id="Hamburger" transform="translate(1.25 1.25)">
							<path
								d="M0.425 1.425L29.325 1.425"
								transform="translate(0 8.075)"
								id="Line-5-Copy"
								fill="none"
								stroke={sideDrawerOpen ? "#005da4" : "#979797"}
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
							<path
								d="M0.875 1.425L15.325 1.425"
								transform="translate(14 16.15)"
								id="Line-5-Copy-2"
								fill="none"
								stroke={sideDrawerOpen ? "#005da4" : "#979797"}
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
							<path
								d="M0.509 1.425L22.325 1.425"
								transform="translate(7 0)"
								id="Line-5"
								fill="none"
								stroke={sideDrawerOpen ? "#005da4" : "#979797"}
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
						</g>
					</svg>

					<span className={styles.navItemText}>SIGN OUT</span>
				</button>
			) : (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<button
					style={{ background: "transparent", border: "none" }}
					className={styles.navItem}
					onClick={() => {
						dispatch(setSideDrawerState(false));
						dispatch(setCurrentModal("login"));
					}}
				>
					<svg width="26px" height="20px" viewBox="0 0 33 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<g id="Hamburger" transform="translate(1.25 1.25)">
							<path
								d="M0.425 1.425L29.325 1.425"
								transform="translate(0 8.075)"
								id="Line-5-Copy"
								fill="none"
								stroke={"#979797"}
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
							<path
								d="M0.875 1.425L15.325 1.425"
								transform="translate(14 16.15)"
								id="Line-5-Copy-2"
								fill="none"
								stroke={"#979797"}
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
							<path
								d="M0.509 1.425L22.325 1.425"
								transform="translate(7 0)"
								id="Line-5"
								fill="none"
								stroke={"#979797"}
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
						</g>
					</svg>

					<span className={styles.navItemText}>SIGN IN</span>
				</button>
			)}
		</div>
	);
};

export default MobileNav;
