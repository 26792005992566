import { ReactNode, createContext, useContext, useState, useEffect } from "react";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { UserData } from "types/user";

import mvApi from "api/general/axiosConfig";

interface AuthContextType {
	user: UserData | undefined;
	isAuthenticated: boolean;
	loading: boolean;
	toggleLogin: (bool: boolean) => void;
	refresh: () => void;
	logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
	const [user, setUser] = useState<UserData>();
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		getUserData();
	}, []);

	const getUserData = async () => {
		try {
			const user = await getCurrentUser();

			let res = await mvApi.get(`/viewer/attributes`);

			setUser({ ...res.data.data, ...user });
			setIsAuthenticated(true);
		} catch (err: any) {
			console.log(err);
			setIsAuthenticated(false);
			setUser(undefined);
			await signOut();
		} finally {
			setLoading(false);
		}
	};
	const refresh = () => {
		getUserData();
	};
	const toggleLogin = (boolean: boolean) => {
		setIsAuthenticated(boolean);
	};

	const logout = async () => {
		setLoading(true);
		try {
			await signOut();
		} catch (err: any) {
			console.error(err);
		} finally {
			window.location.href = "/";
		}
	};

	// if (loading) {
	// 	return <p></p>;
	// }

	return (
		<>
			<AuthContext.Provider value={{ user, isAuthenticated, toggleLogin, refresh, loading, logout }}>
				{children}
			</AuthContext.Provider>
		</>
	);
}

export default function useAuth() {
	return useContext(AuthContext);
}
