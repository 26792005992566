import React, { useEffect, useState } from "react";

import styles from "./Payment.module.css";

export default function SurveyQuestions({ onChange, surveyErrors, submit, isLoading }: any) {
	const [q1Answer, setQ1Answer] = useState<string>("");

	const [q2Answer, setQ2Answer] = useState<string>("");

	const [q1FreeFormEntry, setQ1FreeFormEntry] = useState<string>("");

	const [q2FreeFormEntry, setQ2FreeFormEntry] = useState<string>("");

	useEffect(() => {
		onChange({
			q1Answer: q1Answer,

			q2Answer: q2Answer,

			q1FreeFormEntry: q1FreeFormEntry,

			q2FreeFormEntry: q2FreeFormEntry,
		});
	}, [q1Answer, q2Answer, q1FreeFormEntry, q2FreeFormEntry]);

	return (
		<>
			<div className={styles.surveyQuestionWrapper}>
				<div>
					<label className={styles.questionLabel}>
						How did you learn the game would be streamed on MuscoVision?<span> *</span>
					</label>

					<div className={styles.answersWrapper}>
						<label>
							<input type="radio" value="1" checked={q1Answer === "1"} onChange={(e) => setQ1Answer(e.target.value)} />
							Signage at the field
						</label>

						<br />

						<label>
							<input type="radio" value="2" checked={q1Answer === "2"} onChange={(e) => setQ1Answer(e.target.value)} />
							Word of mouth
						</label>

						<label>
							<input type="radio" value="3" checked={q1Answer === "3"} onChange={(e) => setQ1Answer(e.target.value)} />
							Email
						</label>

						<label>
							<input type="radio" value="4" checked={q1Answer === "4"} onChange={(e) => setQ1Answer(e.target.value)} />
							Social Media
						</label>

						<label>
							<input type="radio" value="5" checked={q1Answer === "5"} onChange={(e) => setQ1Answer(e.target.value)} />
							Website
						</label>

						<label>
							<input type="radio" value="6" checked={q1Answer === "6"} onChange={(e) => setQ1Answer(e.target.value)} />
							Other
							{q1Answer === "6" && (
								<input
									type="text"
									value={q1FreeFormEntry}
									maxLength={150}
									onChange={(e) => setQ1FreeFormEntry(e.target.value)}
									placeholder="Other..."
								/>
							)}
						</label>
					</div>
				</div>

				<div>
					<label className={styles.questionLabel}>
						Who communicated it?<span> *</span>
					</label>

					<div className={styles.answersWrapper}>
						<label>
							<input type="radio" value="1" checked={q2Answer === "1"} onChange={(e) => setQ2Answer(e.target.value)} />
							Team, League, or Coach
						</label>

						<br />

						<label>
							<input type="radio" value="2" checked={q2Answer === "2"} onChange={(e) => setQ2Answer(e.target.value)} />
							Tournament organizer
						</label>

						<label>
							<input type="radio" value="3" checked={q2Answer === "3"} onChange={(e) => setQ2Answer(e.target.value)} />
							The facility owner (city/county parks, school, or private facility owner)
						</label>

						<label>
							<input type="radio" value="4" checked={q2Answer === "4"} onChange={(e) => setQ2Answer(e.target.value)} />
							Family or friend
						</label>

						<label>
							<input type="radio" value="5" checked={q2Answer === "5"} onChange={(e) => setQ2Answer(e.target.value)} />
							Other
							{q2Answer === "5" && (
								<input
									type="text"
									value={q2FreeFormEntry}
									maxLength={150}
									onChange={(e) => setQ2FreeFormEntry(e.target.value)}
									placeholder="Other..."
								/>
							)}
						</label>
					</div>
				</div>
			</div>
			<button
				type="button"
				disabled={isLoading || !q1Answer || !q2Answer}
				className={styles.submitBtn}
				onClick={submit}
			>
				{isLoading ? "SUBMITTING..." : "SUBMIT ANSWERS"}
			</button>
		</>
	);
}
