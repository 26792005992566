import React, { useState, useEffect } from "react";
import styles from "./AddTag.module.css";
import sportTypes from "constants/sportTypes";

import arbiterApi from "api/arbiter/axiosConfig";
import useAuth from "contexts/auth.context";

interface AddTagProps {
	close: () => void;
	setNewTag: (tagName: string) => void;
	sportId: string;
}

const AddTag: React.FC<AddTagProps> = ({ close, setNewTag, sportId }) => {
	const [tagName, setTagName] = useState<string>("");

	let submitTag = async () => {
		if (tagName.length > 0) {
			setNewTag(tagName);
			close();
		}
	};

	return (
		<div className={styles.addTagModalWrapper}>
			<div className={styles.addTagModal}>
				<h3>Add Tag</h3>

				<div className={styles.textInputWrapper}>
					<span className={styles.hash}>#</span>

					<input
						autoFocus
						className={styles.textInput}
						type="text"
						placeholder="Tag Name"
						maxLength={22}
						value={tagName}
						onChange={(e) => setTagName(e.target.value)}
					/>
				</div>

				<div className={styles.buttonGroup}>
					<button className={styles.save} type="button" onClick={() => submitTag()}>
						Save
					</button>

					<button
						className={styles.cancel}
						type="button"
						onClick={() => {
							close();
						}}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddTag;
