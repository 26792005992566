import { Navigate, useLocation } from "react-router-dom";

import { ACCESS_TOKEN } from "constants/auth";
import Cookies from "js-cookie";
import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
	const location = useLocation();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkAuth();
	}, []);
	const checkAuth = async () => {
		try {
			const { tokens } = await fetchAuthSession();
			tokens ? setIsAuthenticated(true) : setIsAuthenticated(false);
		} catch (err: any) {
			setIsAuthenticated(false);
		} finally {
			setLoading(false);
		}
	};
	if (loading) {
		return <p></p>;
	}
	if (!isAuthenticated) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	} else {
		return children;
	}
};

export default PrivateRoute;
