import styles from "./Styles.module.css";
import DownArrow from "../../../../img/down-arrow-blue.svg";
import { Link } from "react-router-dom";

import Spinner from "../../../general/spinner/Spinner";

const DesktopList = ({ data, numOfColumns, showLessData, showMoreData, isGettingMore, moreToShow }) => {
	const renderButton = () => {
		if (data.length >= numOfColumns) {
			return (
				<div className={styles.showDataBtnRow}>
					<div className={styles.horizontalLines}></div>
					{data.length > 6 ? (
						<button className={styles.showLessBtn} onClick={() => showLessData()}>
							<img height="6px" src={DownArrow} alt="Down Arrow" />
							SHOW LESS
						</button>
					) : null}

					{moreToShow ? (
						<button data-testid="facilityMore" className={styles.showMoreBtn} onClick={() => showMoreData()}>
							SHOW MORE
							<img height="6px" src={DownArrow} alt="Down Arrow" />
						</button>
					) : null}

					<div className={styles.horizontalLines}></div>
				</div>
			);
		}
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
				}}
				data-testid="facility-container"
			>
				{data &&
					data.map((item, index) => {
						return (
							<Link
								to={`/facility/${item.id}`}
								className={styles.contentBlock}
								style={numOfColumns === 4 ? { width: "calc(25% - 30px)" } : { width: "calc(33% - 30px)" }}
								key={index}
							>
								<div
									data-testid="contentBlock"
									className={styles.contentBlockImg}
									style={{ backgroundImage: `url(${item.medium_image})` }}
								></div>

								<div className={styles.broadcastInfo}>
									<div>
										<h6 style={{ color: "white" }}>{item.title}</h6>
										<p className={styles.broadcastSubheading}>{item.site_title}</p>
									</div>
									{/* <MoreActions /> */}
								</div>
							</Link>
						);
					})}
			</div>
			{isGettingMore ? <Spinner color="255, 255, 255" /> : renderButton()}
		</>
	);
};

export default DesktopList;
