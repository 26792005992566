import React, { useState } from "react";
const predefinedDurations = [5, 10, 15, 20, 25, 30];
type Props = {
	selectedDuration: number;
	onChange: (dur: number) => void;
};
const DurationSelector = ({ selectedDuration, onChange }: Props) => {
	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		onChange(Number(event.target.value));
	};

	return (
		<div>
			<label htmlFor="valueSelector" style={{ color: "black" }}>
				Duration:{" "}
			</label>
			<select id="valueSelector" value={selectedDuration} onChange={handleChange}>
				{predefinedDurations.map((value) => (
					<option key={value} value={value}>
						{value} seconds
					</option>
				))}
			</select>
		</div>
	);
};

export default DurationSelector;
