import React from "react";
import { Link } from "react-router-dom";
import SideDrawerToggler from "../sideDrawer/SideDrawerToggler";
import { useSelector, useDispatch } from "react-redux";
import MVLogo from "../../../img/MuscoVision_with-tag-white-01.svg";
import FinderIcon from "../../../img/Finder-icon-white.svg";
import HeaderSearch from "./HeaderSearch";
import styles from "./Header.module.css";
import { useNavigate } from "react-router";
import { setCurrentModal } from "../../../redux/actions/modal";
import SearchIcon from "../../../img/Search-icon.svg";
import useAuth from "contexts/auth.context";
import UserIcon from "../../../img/user-solid.svg";
import DownCaretIcon from "../../../img/caret-down-solid.svg";
import GearIcon from "../../../img/gear-solid.svg";
import SignOutIcon from "../../../img/arrow-right-from-bracket-solid.svg";
const Header = () => {
	const { isAuthenticated, user, logout } = useAuth();
	const sideDrawerOpen = useSelector((state: any) => state.general.sideDrawerOpen);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	console.log(user);
	const loggedInHeader = () => {
		return (
			<React.Fragment>
				<Link to="/nearby" className={styles.gamesNearbyLink}>
					<span>
						<img className={styles.nearbyIcon} src={FinderIcon} alt="Games nearby" />
					</span>
					<span className={styles.nearbyText} data-testid="gamesNearby">
						GAMES NEARBY
					</span>
				</Link>
				{user ? (
					<div className={styles.userActions}>
						<div className={styles.userActionsToggler}>
							<img src={UserIcon} width="14px" />
							{/* <span>{user.fullName?.fullName.split(" ")[0]}</span> */}

							<img src={DownCaretIcon} width="8px" />
						</div>
						<div className={styles.actionsDropdown}>
							<button onClick={() => navigate("/account")} data-testid="actBtn">
								<img src={GearIcon} width="12px" style={{ marginRight: ".75rem" }} />
								MY ACCOUNT
							</button>

							<button data-testid="logoutBtn" onClick={() => logout()}>
								<img src={SignOutIcon} width="12px" style={{ marginRight: ".75rem" }} />
								SIGN OUT
							</button>
						</div>
					</div>
				) : null}
			</React.Fragment>
		);
	};

	const loggedOutHeader = () => {
		return (
			<React.Fragment>
				<HeaderSearch />
				<div className={styles.headerLogButtons}>
					<button type="button" className={styles.headerSignup} onClick={() => dispatch(setCurrentModal("register"))}>
						Sign Up
					</button>
					<button type="button" className={styles.headerSignin} onClick={() => dispatch(setCurrentModal("login"))}>
						Sign In
					</button>
				</div>
			</React.Fragment>
		);
	};

	return (
		<header
			className={`${styles.header} ${sideDrawerOpen ? styles.blur : null}`}
			style={
				isAuthenticated
					? { background: "linear-gradient(135deg, #005da4 0%, #003c69 100%)" }
					: { backgroundColor: "transparent" }
			}
		>
			<div className={styles.headerLeft}>
				<div className={styles.sideDrawerToggler}>
					<SideDrawerToggler color="white" />
				</div>

				<Link to="/" style={{ textDecoration: "none" }} tabIndex={2}>
					<img className={styles.headerLogo} src={MVLogo} alt="Musco Vision Logo" />
				</Link>
			</div>
			{isAuthenticated && <HeaderSearch />}
			<Link to="/search" className={styles.mobileHeaderSearch}>
				<img src={SearchIcon} alt="Search Icon" />
			</Link>
			<div className={styles.headerRight}>{isAuthenticated ? loggedInHeader() : loggedOutHeader()}</div>
		</header>
	);
};
export default Header;
