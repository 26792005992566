import React, { useState, useEffect } from "react";
import styles from "./Nearby.module.css";
import MapLoading from "./MapLoading";
import LocationSearch from "./LocationSearch";
import Map from "./map/Map";
import SidebarList from "./SidebarList";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import HeaderIcon from "../../../img/games-nearby-header-icon.svg";
import FilterIcon from "../../../img/Filter-icon-black.svg";
import CloseBtn from "../../../img/X-btn.svg";
import ReactGA from "react-ga4";

export default function Nearby(props) {
	const dispatch = useDispatch();
	const screenWidth = useSelector((state) => state.general.screenWidth);
	const [currentlySelected, setCurrentlySelected] = useState(null);
	const [mapCenter, setMapCenter] = useState(null);
	const [mapZoom, setMapZoom] = useState(5);
	const [filtersAreOpen, setFiltersAreOpen] = useState(false);
	const [currentLocation, setCurrentLocation] = useState(null);
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isInitialized, setIsInitialized] = useState(false);
	const [displaySearchButton, setDisplaySearchButton] = useState(false);

	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	// Runs when currentlySelected is updated
	// This is called by the clicking of a map marker or a facility in the sidebar list
	useEffect(() => {
		if (currentlySelected !== null) {
			// Returns an array containing only the selected facility object
			var selectedObj = data.filter((obj) => {
				return obj.id === currentlySelected;
			});

			// Center the map to the selected facility location
			setMapCenter({
				lat: selectedObj[0].lat,
				lng: selectedObj[0].lng,
			});
		}
	}, [currentlySelected]);

	// Called everytime the map center or map zoom in changed, and when the map initially mounts
	const initializeSearch = async (bounds, center) => {
		if (isInitialized && !displaySearchButton) {
			setDisplaySearchButton(true);
		}
		if (!isInitialized) {
			setIsLoading(true);
			let locationFrom = "";
			if (currentLocation !== null) {
				locationFrom = `${currentLocation.lat},${currentLocation.lng}`;
			}
			let nwCoords = `${bounds.nw.lat},${bounds.nw.lng}`;
			let seCoords = `${bounds.se.lat},${bounds.se.lng}`;

			await fetch(
				`https://arbiter.musco.io/pub/facility?current_location=${locationFrom}&north_west=${nwCoords}&south_east=${seCoords}`
			)
				.then((response) => response.text())
				.then((result) => {
					let data = JSON.parse(result);

					setData(data);
					setIsLoading(false);
				})
				.catch(() => alert("There was a problem connecting to the server."));

			setIsInitialized(true);
		}
	};

	const triggerSearch = async (bounds, center) => {
		setDisplaySearchButton(false);
		setIsLoading(true);
		let locationFrom = "";
		if (currentLocation !== null) {
			locationFrom = `${currentLocation.lat},${currentLocation.lng}`;
		}

		let nwCoords = `${bounds.nw.lat},${bounds.nw.lng}`;

		let seCoords = `${bounds.se.lat},${bounds.se.lng}`;

		await fetch(
			`https://arbiter.musco.io/pub/facility?current_location=${locationFrom}&north_west=${nwCoords}&south_east=${seCoords}`
		)
			.then((response) => response.text())
			.then((result) => {
				let data = JSON.parse(result);

				setData(data);
				setIsLoading(false);
			})
			.catch(() => alert("There was a problem connecting to the server."));
	};

	// Change the currently selected facility
	const setSelected = (id) => {
		setCurrentlySelected(id);
	};

	const changeLocation = (coordinates) => {
		setMapCenter({
			lat: coordinates.lat,
			lng: coordinates.lng,
		});

		setMapZoom(14);
	};

	const setUsersLocation = () => {
		function successCallback(position) {
			setCurrentLocation({
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			});
			setMapCenter({
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			});
		}
		function errorCallback(err) {
			// TODO: Fix this
		}
		navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

		setMapZoom(14);
	};

	const renderFilters = () => {
		if (screenWidth < 800) {
			if (filtersAreOpen) {
				return (
					<LocationSearch
						changeLocation={(coordinates) => changeLocation(coordinates)}
						setCurrentLocation={() => setUsersLocation()}
					/>
				);
			}
		} else {
			return (
				<LocationSearch
					changeLocation={(coordinates) => changeLocation(coordinates)}
					setCurrentLocation={() => setUsersLocation()}
				/>
			);
		}
	};

	return (
		<div className={styles.nearby}>
			<Helmet>
				<title>Musco Vision</title>
				<meta name="description" content="Games Nearby" />
			</Helmet>
			<div className={styles.header}>
				<h1>
					<img src={HeaderIcon} style={{ marginRight: "1rem" }} />
					GAMES NEARBY
				</h1>
				<p>Find live broadcasts in your area</p>
			</div>
			<div className={styles.filters}>
				{screenWidth < 800 ? (
					<button className={styles.filterTogglerBtn} onClick={() => setFiltersAreOpen(!filtersAreOpen)}>
						<img className={styles.filtersIcon} src={FilterIcon} /> Search filters
						{filtersAreOpen ? <img className={styles.closeBtn} src={CloseBtn} /> : null}
					</button>
				) : null}

				{renderFilters()}
			</div>

			<div className={styles.mapArea}>
				<SidebarList
					setSelected={(id) => setSelected(id)}
					data={data}
					currentLocation={currentLocation}
					isLoading={isLoading}
					currentlySelected={currentlySelected}
				/>
				<Map
					setSelected={(id) => setSelected(id)}
					data={data}
					currentlySelected={currentlySelected}
					mapCenter={mapCenter}
					mapZoom={mapZoom}
					changeBounds={(bounds, center) => initializeSearch(bounds, center)}
					reSearch={(bounds, center) => triggerSearch(bounds, center)}
					currentLocation={currentLocation}
					showButton={displaySearchButton}
				/>
			</div>
			{/*  */}
		</div>
	);
}
