import React from "react";
import Desktop from "./DesktopList";
import Mobile from "./mobileList/MobileList";
import { useSelector } from "react-redux";
import { BroadcastObject } from "types/broadcast";

interface Props {
	data: BroadcastObject[];
	numOfColumns: number;
	breakpoint: number;
	showMoreData: () => void;
	showLessData: () => void;
	isGettingMore: boolean;
	moreToShow: boolean;
}
const BroadcastList = ({
	data,
	numOfColumns,
	breakpoint,
	showMoreData,
	showLessData,
	isGettingMore,
	moreToShow,
}: Props) => {
	const screenWidth = useSelector((state: any) => state.general.screenWidth);

	return (
		<div data-testid="recentCon" style={{ margin: "0 auto", maxWidth: "1200px" }}>
			{/* Render the Desktop or Mobile component based on the current device width */}
			{data.length !== 0 ? (
				screenWidth > breakpoint ? (
					<Desktop
						data={data}
						numOfColumns={numOfColumns}
						showMoreData={showMoreData}
						isGettingMore={isGettingMore}
						moreToShow={moreToShow}
						showLessData={showLessData}
					/>
				) : (
					<Mobile data={data} showMoreData={showMoreData} isGettingMore={isGettingMore} moreToShow={moreToShow} />
				)
			) : null}
		</div>
	);
};

export default BroadcastList;
