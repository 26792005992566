import { useState } from "react";

import { useEffect } from "react";

import styles from "./TagFilters.module.css";

export default function TagOptionPill({ tag, tagsToFilter, handleChange }: any) {
	const [active, setActive] = useState(false);

	useEffect(() => {
		const isActive = tagsToFilter.some((item: string) => item === tag);
		setActive(isActive);
	}, [tagsToFilter]);

	return (
		<button
			onClick={() => handleChange({ active: !active, tag: tag })}
			className={`${styles.tagButton} ${active ? styles.active : ""}`}
		>
			#{tag}
		</button>
	);
}
