import React, { useState, useEffect } from "react";

import styles from "./Auth.module.css";
import Spinner from "../../general/spinner/Spinner";
import ReactGA from "react-ga4";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
export default function ForgotPassword() {
	const [email, setEmail] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [newPassword, setNewPassword] = useState("");
	const [screen, setScreen] = useState<number>(0);
	const [errorMessage, setErrorMessage] = useState<string[]>(null!);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		ReactGA.event({
			category: "Modal",
			action: "Reset Password Modal",
		});
	}, []);

	const handleEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		setErrorMessage(null!);
		// Send confirmation code to user's email
		await resetPassword({ username: email })
			.then((data) => {
				setScreen(1);
			})
			.catch((err) => {
				switch (err.message) {
					case "Username/client id combination not found.":
						setErrorMessage(["There was a problem validating your email.", "Please make sure that it is correct."]);

						break;
					case "Cannot reset password for the user as there is no registered/verified email or phone_number":
						setErrorMessage([
							"You cannot reset your password because your email is not verified.",
							"Please contact support to recover your account:",
							"support@muscovision.com",
						]);

						break;
					default:
						setErrorMessage(["We could not verify "]);
				}
			});
		setIsLoading(false);
	};
	const handlePasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		setIsLoading(true);
		e.preventDefault();
		setErrorMessage(null!);
		// Collect confirmation code and new password, then
		await confirmResetPassword({ username: email, confirmationCode: code, newPassword: newPassword })
			.then(() => {
				setScreen(2);
			})
			.catch((err) => setErrorMessage([err.message]));
		setIsLoading(false);
	};

	return (
		<div className={styles.formWrapper}>
			<h3
				style={{
					color: "black",
					marginBottom: "1.5rem",
					textAlign: "center",
				}}
			>
				RESET PASSWORD
			</h3>
			{isLoading ? (
				<div style={{ marginTop: "20px" }}>
					<Spinner color="0, 93, 164" />
				</div>
			) : (
				<>
					{screen === 0 ? (
						<>
							<p className={styles.generalMessage}>Enter your email below.</p>

							<form onSubmit={handleEmailSubmit}>
								<div className={styles.inputGroup}>
									<label className={styles.label}>EMAIL</label>
									<input
										autoFocus
										type="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										placeholder="EMAIL ADDRESS"
									/>
								</div>
								<button type="submit" className={styles.submitBtn}>
									SUBMIT
								</button>
							</form>
						</>
					) : screen === 1 ? (
						<>
							<p className={styles.generalMessage}>
								We have sent a confirmation code to your email.
								<br /> Please enter the code in the box below along with your new password.
							</p>
							<form onSubmit={handlePasswordSubmit}>
								<div className={styles.inputGroup}>
									<label className={styles.label}>CODE</label>
									<input
										autoFocus
										type="text"
										value={code}
										onChange={(e) => setCode(e.target.value)}
										placeholder="Code"
									/>
								</div>
								<div className={styles.inputGroup}>
									<label className={styles.label}>NEW PASSWORD</label>
									<input
										type="password"
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										placeholder="New Password"
									/>
								</div>
								<button type="submit" className={styles.submitBtn}>
									SUBMIT
								</button>
							</form>
						</>
					) : screen === 2 ? (
						<p className={styles.successMessage}>Your password has been successfully changed.</p>
					) : null}
					{errorMessage ? (
						<div style={{ marginTop: "20px" }}>
							{errorMessage.map((item) => (
								<p style={{ textAlign: "center" }} className={styles.errorMessage}>
									{item}
								</p>
							))}
						</div>
					) : null}
				</>
			)}
		</div>
	);
}
