import { useState, useEffect } from "react";
import Spinner from "../../../../../components/general/spinner/Spinner";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";

let CarouselContainer = ({ broadcasts, isLoading }) => {
	const [cards, setCards] = useState(null);

	useEffect(() => {
		if (!isLoading && broadcasts) {
			let slideContent = broadcasts.map((broadcast, index) => {
				return {
					key: uuidv4(),
					content: <Card image={broadcast.large_image} title={broadcast.title} id={broadcast.id} />,
				};
			});

			setCards(slideContent);
		}
	}, [broadcasts]);

	return (
		<div
			style={{
				width: "100%",
				height: "600px",
				margin: "0 auto",
				display: "flex",
				alignItems: "center",
			}}
		>
			{cards && !isLoading ? (
				<Carousel cards={cards} height="600px" width="100%" margin="0 auto" offset={2} showArrows={false} />
			) : (
				<Spinner color="255, 255, 255" />
			)}
		</div>
	);
};

export default CarouselContainer;
