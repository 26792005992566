import { useState } from "react";
import styles from "./Account.module.css";
import MyTags from "./myTags";
import AccountDetails from "./accountDetails";
import useAuth from "contexts/auth.context";
import UserIcon from "../../../img/user-solid.svg";
import Overview from "./overview";
export default function Account() {
	const { user, refresh } = useAuth();
	console.log(user);
	const ACCOUNT_DETAILS = "ACCOUNT DETAILS";
	const MY_TAGS = "MY TAGS";
	const OVERVIEW = "OVERVIEW";
	const [tabSelected, setTabSelected] = useState<string>(OVERVIEW);
	// const select = useRef(null);
	const onSelectChange = (e: any) => {
		let selectedOption = e.target.value;

		switch (selectedOption) {
			case OVERVIEW:
				setTabSelected(OVERVIEW);
				break;
			case ACCOUNT_DETAILS:
				setTabSelected(ACCOUNT_DETAILS);
				break;
			// case MY_TAGS:
			// 	setTabSelected(MY_TAGS);
			// 	break;
			default:
				setTabSelected(OVERVIEW);
		}
	};

	const renderContent = () => {
		switch (tabSelected) {
			case OVERVIEW:
				return <Overview />;
			case ACCOUNT_DETAILS:
				return <AccountDetails />;
			// case MY_TAGS:
			// 	return <MyTags />;
		}
	};
	return (
		<div className={styles.account}>
			<section style={{ margin: "3rem 0" }}>
				<h1>
					<img src={UserIcon} width="23px" style={{ marginRight: "1rem" }} />
					{user?.fullName.fullName}
				</h1>
			</section>
			<section className={styles.accountNav}>
				<nav>
					<ul>
						<li
							style={tabSelected === OVERVIEW ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
							onClick={() => setTabSelected(OVERVIEW)}
						>
							{OVERVIEW}
						</li>
						<li
							style={tabSelected === ACCOUNT_DETAILS ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
							onClick={() => setTabSelected(ACCOUNT_DETAILS)}
						>
							{ACCOUNT_DETAILS}
						</li>

						{/* <li
							style={tabSelected === MY_TAGS ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
							onClick={() => setTabSelected(MY_TAGS)}
						>
							{MY_TAGS}
						</li> */}
					</ul>
				</nav>
			</section>

			<section className={styles.accountNavMobile}>
				<select onChange={(e) => onSelectChange(e)} value={tabSelected}>
					<option
						value={OVERVIEW}
						style={tabSelected === OVERVIEW ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
					>
						{OVERVIEW}
					</option>

					<option
						value={ACCOUNT_DETAILS}
						style={tabSelected === ACCOUNT_DETAILS ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
					>
						{ACCOUNT_DETAILS}
					</option>

					{/* <option value={MY_TAGS} style={tabSelected === MY_TAGS ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}>
						{MY_TAGS}
					</option> */}
				</select>
			</section>
			{renderContent()}
		</div>
	);
}
