import React, { useState } from "react";
import styles from "../AccountDetails.module.css";

import { confirmUserAttribute, updateUserAttributes } from "aws-amplify/auth";
import mvApi from "api/general/axiosConfig";

interface EmailProps {
	user: any; // Replace 'any' with the actual type of your user object
	refreshData: () => void;
}

const Email: React.FC<EmailProps> = ({ user, refreshData }) => {
	const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [value, setValue] = useState<string>(user.email);
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const [confirmationCode, setConfirmationCode] = useState<string>("");
	const [displayConfirmationField, setDisplayConfirmationField] = useState<boolean>(false);

	const submit = async () => {
		if (value !== user.email) {
			setLoading(true);
			try {
				let body = { newEmail: value };
				await mvApi.post("/viewer/updateEmail", body);
				setError(undefined);
				setSuccess(
					"A verification code has been sent to your updated email address. Please enter this code in the box above to verify this new email address is yours."
				);
				setDisplayConfirmationField(true);
				// refreshData();
			} catch (err: any) {
				console.log(err);
				setError("An unexpected error occurred while attempting to update your email. Please try again.");
			} finally {
				setLoading(false);
			}
		}
	};

	const confirm = async () => {
		setLoading(true);

		try {
			await confirmUserAttribute({ userAttributeKey: "email", confirmationCode });
			setSuccess("Your email has been verified.");
			setDisplayConfirmationField(false);
			setEditModeEnabled(false);
			refreshData();
		} catch (err: any) {
			if (err && err.message) {
				setError(err.message);
			} else {
				setError("An unexpected error has occurred. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className={styles.accountItem}>
			<div className={styles.row}>
				<div className={styles.left}>
					<h3>EMAIL</h3>
					<br />
					{editModeEnabled ? (
						<>
							{!displayConfirmationField ? (
								<input
									className={styles.textInput}
									type="text"
									value={value}
									disabled={loading}
									onChange={(e) => setValue(e.target.value)}
								/>
							) : (
								<>
									<input
										className={styles.textInput}
										type="text"
										placeholder="Confirmation Code"
										value={confirmationCode}
										disabled={loading}
										onChange={(e) => setConfirmationCode(e.target.value)}
									/>
									<div className={styles.buttonGroup}>
										<button className={styles.save} type="button" onClick={() => confirm()} disabled={loading}>
											{loading ? "Confirming..." : "Confirm"}
										</button>
									</div>
								</>
							)}
						</>
					) : (
						<p>{user.email}</p>
					)}
				</div>
				{!editModeEnabled ? (
					<button className={styles.edit} type="button" onClick={() => setEditModeEnabled(true)}>
						Edit
					</button>
				) : null}
			</div>
			{error ? <p style={{ color: "red", marginTop: "10px" }}>{error}</p> : null}
			{success ? <p style={{ marginTop: "10px", color: "green" }}>{success}</p> : null}

			{editModeEnabled && !displayConfirmationField ? (
				<div className={styles.buttonGroup}>
					<button className={styles.save} type="button" onClick={() => submit()} disabled={loading}>
						{loading ? "Saving..." : "Save"}
					</button>
					<button
						className={styles.cancel}
						type="button"
						onClick={() => {
							setEditModeEnabled(false);
							setError(undefined);
						}}
						disabled={loading}
					>
						Cancel
					</button>
				</div>
			) : null}
		</div>
	);
};

export default Email;
