import React, { useState, useEffect } from "react";
import useEffectSkipFirst from "../../../../components/utils/useEffectSkipFirst";
import SportFilter from "./SportFilter";
import DateFilter from "./DateFilter";
import SearchFilter from "./SearchFilter";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import styles from "./Filters.module.css";
export default function Filters({ mobileFiltersOpen, setMobileFiltersOpen, setFilters, parentProps }) {
	const screenWidth = useSelector((state) => state.general.screenWidth);
	const [refinedSearch, setRefinedSearch] = useState("");
	const [newURL, setNewURL] = useState(window.location.search);
	const [filtersChanged, setFiltersChanged] = useState(false);
	// const [hasURLParams, setHasURLParams] = useState(true);
	const [sportFilters, setSportFilters] = useState({
		baseball: false,
		football: false,
		soccer: false,
		softball: false,
		basketball: false,
	});
	const [dateFilters, setDateFilters] = useState({
		startDate: null,
		endDate: null,
	});

	// Called whenever URL changes and when component mounts
	useEffect(() => {
		const queryString = window.location.search;

		if (queryString !== "") {
			const urlParams = new URLSearchParams(queryString);
			const searchString = urlParams.get("q");

			setRefinedSearch(searchString);
			parseUrlFilters();
		}
	}, [window.location.search]);

	useEffectSkipFirst(() => {
		if (newURL !== window.location.search) {
			setFiltersChanged(true);
		} else {
			setFiltersChanged(false);
		}
	}, [newURL]);

	useEffectSkipFirst(() => {
		stageNewURL();
	}, [sportFilters, dateFilters, refinedSearch]);

	const getDateFilters = (dates) => {
		let endDate = "";
		let startDate = "";

		if (dates.endDate instanceof Date && !isNaN(dates.endDate.valueOf())) {
			endDate = dates.endDate.toISOString();
		}
		if (dates.startDate instanceof Date && !isNaN(dates.startDate.valueOf())) {
			startDate = dates.startDate.toISOString();
		}
		return { startDate: startDate, endDate: endDate };
	};
	const getSportFilters = (sports) => {
		let sportsArray = [];
		for (const key in sportFilters) {
			if (sports[key]) {
				switch (key) {
					case "baseball":
						sportsArray.push("baseball");
						break;
					case "football":
						sportsArray.push("football");
						break;
					case "soccer":
						sportsArray.push("soccer");
						break;
					case "softball":
						sportsArray.push("softball");
						break;
					case "basketball":
						sportsArray.push("basketball");
						break;
					default:
				}
			}
		}
		return sportsArray;
	};
	// Extracts the params from URL and sets them to state
	const parseUrlFilters = () => {
		let queryString = window.location.search;
		let urlParams = new URLSearchParams(queryString);

		const searchString = urlParams.get("q");

		let datesToSet = {
			startDate: null,
			endDate: null,
		};
		let sportsToSet = {
			baseball: false,
			football: false,
			soccer: false,
			softball: false,
			basketball: false,
		};
		let sports = urlParams.get("sports");
		if (sports) {
			let sportsArray = sports.split(",");

			sportsArray.forEach((item) => {
				switch (item.toLowerCase()) {
					case "baseball":
						sportsToSet.baseball = true;
						break;
					case "football":
						sportsToSet.football = true;
						break;
					case "soccer":
						sportsToSet.soccer = true;

						break;
					case "softball":
						sportsToSet.softball = true;

						break;
					case "basketball":
						sportsToSet.basketball = true;

						break;

					default:
				}
			});
		}
		let startDate = urlParams.get("startDate");
		let endDate = urlParams.get("endDate");

		if (startDate) {
			let newDate = new Date(startDate);
			if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
				datesToSet.startDate = newDate;
			}
		}
		if (endDate) {
			let newDate = new Date(endDate);
			if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
				datesToSet.endDate = newDate;
			}
		}

		setDateFilters(datesToSet);
		setSportFilters(sportsToSet);
		let parsedDates = getDateFilters(datesToSet);
		let parsedSports = getSportFilters(sportsToSet);

		setFilters({
			dates: parsedDates,
			sports: parsedSports,
			searchQuery: searchString,
		});
	};

	const pushNewURL = (e) => {
		e.preventDefault();
		setMobileFiltersOpen(false);
		window.location.href = window.location.pathname + newURL;
		setFiltersChanged(false);
	};

	// Called by the submit button. Adds the filters to the URL
	const stageNewURL = () => {
		let currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set("q", refinedSearch);
		let parseSports = () => {
			let sportsArray = [];
			for (const key in sportFilters) {
				if (sportFilters[key]) {
					switch (key) {
						case "baseball":
							sportsArray.push("baseball");
							break;
						case "football":
							sportsArray.push("football");
							break;
						case "soccer":
							sportsArray.push("soccer");
							break;
						case "softball":
							sportsArray.push("softball");
							break;
						case "basketball":
							sportsArray.push("basketball");
							break;
						default:
					}
				}
			}
			return sportsArray.toString();
		};
		let sportsArray = parseSports();

		if (sportsArray.length > 0) {
			currentUrlParams.set("sports", sportsArray);
		} else {
			currentUrlParams.delete("sports");
		}

		if (dateFilters.startDate instanceof Date && !isNaN(dateFilters.startDate)) {
			currentUrlParams.set("startDate", dateFilters.startDate);
		} else {
			currentUrlParams.delete("startDate");
		}
		if (dateFilters.endDate instanceof Date && !isNaN(dateFilters.endDate)) {
			currentUrlParams.set("endDate", dateFilters.endDate);
		} else {
			currentUrlParams.delete("endDate");
		}

		setNewURL("?" + currentUrlParams.toString());
	};

	if (screenWidth > 900 || mobileFiltersOpen) {
		if (screenWidth < 900) {
			return (
				<motion.div
					className={styles.mobileFilterMenu}
					initial={{ scale: 0.75, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{
						type: "tween",
						stiffness: 240,
						damping: 20,
					}}
				>
					<form className={styles.sidebar}>
						<SportFilter selected={sportFilters} onChange={(sports) => setSportFilters(sports)} />
						<DateFilter onChange={(dates) => setDateFilters(dates)} dates={dateFilters} />
						<SearchFilter refinedSearch={refinedSearch} onChange={(search) => setRefinedSearch(search)} />

						<button
							data-testid="applyBtn"
							disabled={!filtersChanged}
							type="button"
							onClick={(e) => pushNewURL(e)}
							className={styles.applyBtn}
						>
							APPLY
						</button>
					</form>
				</motion.div>
			);
		} else {
			return (
				<form className={styles.sidebar}>
					<SportFilter selected={sportFilters} onChange={(sports) => setSportFilters(sports)} />
					<DateFilter onChange={(dates) => setDateFilters(dates)} dates={dateFilters} />
					<SearchFilter refinedSearch={refinedSearch} onChange={(search) => setRefinedSearch(search)} />

					<button
						data-testid="applyBtn"
						disabled={!filtersChanged}
						type="button"
						onClick={(e) => pushNewURL(e)}
						className={styles.applyBtn}
					>
						APPLY
					</button>
				</form>
			);
		}
	} else return null;
}
