import React, { useState, useEffect } from "react";
import BroadcastList from "./broadcastList";
import LoadingPlaceholder from "../loadingPlaceholder/LoadingPlaceholder";
import styles from "../Styles.module.css";
import useEffectSkipFirst from "../../utils/useEffectSkipFirst";
import Icon from "../../../img/Baseball-diamond-icon.svg";
import fetchBroadcasts from "./fetchBroadcasts";

type Props = {
	facilityID: string;
	updateParent: (v: any) => void;
	viewerStatus: string;
	isLoading: boolean;
	filters: any;
	hideTitle: boolean;
};
export default function GetBroadcasts({
	facilityID,
	updateParent,
	viewerStatus,
	isLoading,
	filters,
	hideTitle,
}: Props) {
	const [state, setState] = useState<any>({
		broadcasts: [],
		isGettingMore: false,

		perPage: 6,
		cursor: "",
		isLoading: true,
		reset: false,
	});
	const [currentFilters, setCurrentFilters] = useState({
		dates: {
			startDate: "",
			endDate: "",
		},
		sports: [],
		searchQuery: "",
	});
	const [currentStatus, setCurrentStatus] = useState(viewerStatus);
	const [errorMessage, setErrorMessage] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			getBroadcasts();
		}
	}, [isLoading]);

	useEffectSkipFirst(() => {
		if (filters) {
			setCurrentFilters(filters);
		}

		setCurrentStatus(viewerStatus);

		if (JSON.stringify(filters) !== JSON.stringify(currentFilters) || viewerStatus !== currentStatus) {
			resetData();
		}
	}, [filters, viewerStatus]);

	useEffectSkipFirst(() => {
		if (state.reset) {
			getBroadcasts();
		}
	}, [state.reset]);

	const getBroadcasts = async () => {
		fetchBroadcasts({ filters, facilityID, cursor: state.cursor, perPage: state.perPage, viewerStatus })
			.then((res: any) => {
				setState({
					...state,
					broadcasts: [...state.broadcasts, ...res.broadcasts],
					cursor: res.cursor,
					reset: false,
					isLoading: false,
					isGettingMore: false,
				});
				updateParent(res.broadcasts);
			})
			.catch((e) => {
				setErrorMessage(true);
				setState({ ...state, isLoading: false });
			});
	};

	const showMoredata = () => {
		setState({ ...state, isGettingMore: true });
		getBroadcasts();
	};
	const resetData = () => {
		setState({
			broadcasts: [],
			isGettingMore: false,
			cursor: "",
			perPage: 6,
			numOfPages: null,
			isLoading: true,
			reset: true,
		});
	};
	return (
		<>
			<div className={styles.headingRow}>
				{!hideTitle ? (
					<>
						<div className={styles.icon}>
							<img src={Icon} />
						</div>
						{viewerStatus === "streaming" ? (
							<h3 className={styles.sectionTitle}>LIVE BROADCASTS</h3>
						) : viewerStatus === "scheduled" ? (
							<h3 className={styles.sectionTitle}>UPCOMING BROADCASTS</h3>
						) : viewerStatus === "archived" ? (
							<h3 className={styles.sectionTitle}>RECENT BROADCASTS</h3>
						) : null}
					</>
				) : null}
			</div>
			{state.isLoading || isLoading ? (
				<LoadingPlaceholder />
			) : state.broadcasts.length === 0 || errorMessage ? (
				<div className={styles.noDataMessage}>
					{viewerStatus === "streaming" ? (
						<p data-testid="liveCast">There are no live broadcasts to display.</p>
					) : viewerStatus === "scheduled" ? (
						<p data-testid="upCast">There are no upcoming broadcasts to display.</p>
					) : viewerStatus === "archived" ? (
						<p>There are no recent broadcasts to display.</p>
					) : null}
				</div>
			) : (
				<BroadcastList
					data-testid="broadcastList"
					data={state.broadcasts}
					numOfColumns={3}
					breakpoint={800}
					showMoreData={showMoredata}
					showLessData={resetData}
					isGettingMore={state.isGettingMore}
					moreToShow={state.cursor !== null}
				/>
			)}
		</>
	);
}
