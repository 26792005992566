import styles from "./Styles.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
function Card({ image, title, id }) {
	const [show, setShown] = useState(false);

	const props3 = useSpring({
		transform: show ? "scale(1.03)" : "scale(1)",
	});
	return (
		<animated.div
			className={styles.card}
			style={props3}
			onMouseEnter={() => setShown(true)}
			onMouseLeave={() => setShown(false)}
		>
			<Link to={`/broadcast/${id}`}>
				<img src={image} className={styles.carouselImage} alt="" />

				<h4>{title}</h4>
			</Link>
		</animated.div>
	);
}

export default Card;
