import React, { useState } from "react";
import styles from "./Home.module.css";
import Arrow from "../../../img/faq-arrow.svg";

interface Props {
	question: string;
	answer: any;
}

const Faq = ({ question, answer }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<button className={styles.faqBlock} onClick={() => setIsOpen(!isOpen)}>
			<div className={styles.faqQuestion}>
				<p>{question}</p>
				<img
					style={isOpen ? { transform: "rotate(90deg)" } : { transform: "rotate(0)" }}
					className={styles.faqBlockArrow}
					src={Arrow}
					alt="Temp"
				/>
			</div>
			<div
				style={isOpen ? { maxHeight: "200px", padding: "1rem 2rem" } : { maxHeight: 0 }}
				className={styles.faqAnswer}
			>
				<p>{answer}</p>
			</div>
		</button>
	);
};

export default Faq;
