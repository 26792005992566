import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { AuthProvider } from "contexts/auth.context";
// import "bootstrap/dist/css/bootstrap.min.css";

const { store, persistor } = configureStore();

Amplify.configure(config);

const container = document.getElementById("root");
const root = createRoot(container!); // Create a root.

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</PersistGate>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
