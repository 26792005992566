import React, { useEffect, useRef, useState } from "react";

import styles from "../TagSidebar.module.css";
import { TagDefinition } from "types/tagging";

type Props = {
	tagClicked: () => void;
	tagName: string;
	tagsSelected: TagDefinition[];
	visibility: "public" | "private";
};
export default function Tag({ tagClicked, tagName, tagsSelected, visibility }: Props) {
	const tagRef = useRef(null);

	const [selected, setSelected] = useState(false);

	useEffect(() => {
		if (tagsSelected.length === 0) {
			setSelected(false);

			return;
		} else if (tagsSelected.filter((tag: TagDefinition) => tag.name === tagName).length > 0) {
			setSelected(true);
		}
	}, [tagsSelected]);

	return (
		<button
			disabled={selected}
			ref={tagRef}
			onClick={() => {
				tagClicked();

				setSelected(true);
			}}
			className={`${styles.tag}  ${visibility === "private" ? styles.private : styles.public}`}
		>
			{tagName}
		</button>
	);
}
