import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-mux";
import FullscreenIcon from "../../../../../../img/expand-solid.svg";
// import "../video.css";

import Player from "video.js/dist/types/player";
interface ExtendedPlayer extends Player {
	controlBar?: any;
}
type Props = {
	src: string;
};
export default function ClipViewer({ src }: Props) {
	const videoRef = useRef<any>();
	const playerRef = useRef<ExtendedPlayer>();
	const videoWrapperRef = useRef<any>();

	useEffect(() => {
		return function () {
			document.removeEventListener("fullscreenchange", () => {});
		};
	}, []);

	const toggleFullScreen = () => {
		if (!videoWrapperRef.current) return;

		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		} else {
			let elem: any = videoWrapperRef.current;

			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			}
		}
	};

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = videoRef.current;

			if (!videoElement) return;

			const video = (playerRef.current = videojs(videoElement, {
				sources: [{ src, type: "application/x-mpegURL" }],

				autoplay: true,

				controls: true,

				responsive: true,

				controlBar: {
					fullscreenToggle: false,
				},

				fluid: true,

				plugins: {
					mux: {
						debug: false,

						data: {
							env_key: "s3lebqhfr5ine2bl51t8ht9vq",

							player_name: "WEB",

							player_init_time: Date.now(),

							// viewer_user_id: user!.userID,

							// sub_property_id: broadcastData.site_id,

							// player_version: "1.0.0",

							// video_id: broadcastData.id,

							// video_title: broadcastData.title,

							// // video_series: broadcastData.field_id,

							// video_duration: broadcastData.expected_duration,

							// video_stream_type: broadcastData.status,
						},
					},
				},
			}));

			video.ready(() => {
				video.on("loadedmetadata", () => {
					let player = playerRef.current;

					var myButton = player?.controlBar?.addChild(
						"button",

						{
							clickHandler: () => toggleFullScreen(),
						},

						9
					);

					var myButtonDom = myButton.el();

					myButtonDom.innerHTML = `<img



                        class="fullscreenToggle"



                            alt="Fullscreen Toggle Button"



                            src=${FullscreenIcon}



                        />`;
				});
			});
		}

		return function () {
			if (playerRef.current != null) {
				playerRef.current.off("loadedmetadata", () => null);

				playerRef.current.off("ready", () => null);
			}
		};
	}, [videoRef]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player) {
				player.dispose();

				playerRef.current = undefined;
			}
		};
	}, [playerRef]);
	return (
		<div className="vidWrapper" ref={videoWrapperRef}>
			<video
				id="video-js-player"
				controls
				autoPlay
				muted
				preload="auto"
				ref={videoRef}
				style={{ width: "100%", height: "100%" }}
				className="video-js vjs-big-play-centered"
			></video>
		</div>
	);
}
