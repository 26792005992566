import { useState, useEffect } from "react";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styles from "./Payment.module.css";
import PaymentForm from "./PaymentForm";
import SubscriptionSelect from "./SubscriptionSelect";
import PaymentConfirmation from "./PaymentConfirmation";
import { add, format } from "date-fns";
import { useDispatch } from "react-redux";
import { setCurrentModal, setErrorData, setLoading } from "../../../../redux/actions/modal";

import Spinner from "../../../../components/general/spinner/Spinner";
import ReactGA from "react-ga4";

import useAuth from "contexts/auth.context";
import mvApi from "api/general/axiosConfig";
import { AccessPass } from "types/payment";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import SurveyQuestions from "./SurveyQuestions";
import DownCarot from "../../../../img/down-arrow-white.svg";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.

export default function Payment() {
	const { user } = useAuth();
	const dispatch = useDispatch();
	const [clientSecret, setClientSecret] = useState<string>();
	const [selectedSubscription, setSelectedSubscription] = useState<AccessPass>();
	const [paymentSucceeded, setPaymentSucceeded] = useState(false);

	const [subscriptionsLoading, setSubscriptionsLoading] = useState<any>(false);
	const [products, setProducts] = useState<AccessPass[]>();
	const [apiKey, setApiKey] = useState(null);
	const [stripePromise, setStripePromise] = useState<any>(null);
	const [openAccordion, setOpenAccordion] = useState<number>();
	const [payIntentLoading, setPayIntentLoading] = useState<boolean>(false);

	const [surveySubmitted, setSurveySubmitted] = useState<boolean>(false);
	const [submitSurveyLoading, setSubmitSurveyLoading] = useState<boolean>(false);
	const [surveyAnswers, setSurveyAnswers] = useState({
		q1Answer: "",
		q2Answer: "",
		q1FreeFormEntry: "",
		q2FreeFormEntry: "",
	});

	const [surveyErrors, setSurveyErrors] = useState({
		q1: "",
		q2: "",
	});
	useEffect(() => {
		// Load the Stripe library once the api key is retrieved
		if (apiKey !== null) {
			setStripePromise(loadStripe(apiKey));
		}
	}, [apiKey]);

	useEffect(() => {
		ReactGA.event({
			category: "Modal",
			action: "Payment Modal",
		});
		getSubscriptions();
	}, []);

	useEffect(() => {
		if (selectedSubscription) {
			if (!surveySubmitted) {
				setOpenAccordion(1);
			}

			createPaymentIntent();
		}
	}, [selectedSubscription]);

	const submitSurvey = async () => {
		const checkFormCompletion = () => {
			let hasErrors = false;

			if (surveyAnswers.q1Answer === "") {
				setSurveyErrors({ ...surveyErrors, q1: "Please select an option." });
				hasErrors = true;
			}

			if (surveyAnswers.q2Answer === "") {
				setSurveyErrors({ ...surveyErrors, q2: "Please select an option." });
				hasErrors = true;
			}

			return hasErrors;
		};
		let hasErrors = checkFormCompletion();
		if (!hasErrors) {
			setSubmitSurveyLoading(true);
			try {
				await mvApi.post("/survey/submit", surveyAnswers);
			} catch (err) {
				// Take no action
			} finally {
				setSurveySubmitted(true);
				setOpenAccordion(2);
				setSubmitSurveyLoading(false);
			}
		}
	};

	let createPaymentIntent = async () => {
		setPayIntentLoading(true);
		let body = {
			paymentType: ["card"],
			description: `MuscoVision Access Pass - Web - ${selectedSubscription!.name}`,

			metadata: {
				userID: user!.userID,
				userEmail: user!.email,
				passID: selectedSubscription!.passID,
			},
		};
		try {
			let res = await mvApi.post("payment/createPaymentIntent", body);
			setClientSecret(res.data.client_secret);
		} catch (err: any) {
			dispatch(
				setErrorData({
					message: "An unexpected error occurred",
				})
			);
			dispatch(setCurrentModal("error"));
		} finally {
			setPayIntentLoading(false);
		}
	};
	const getSubscriptions = async () => {
		setSubscriptionsLoading(true);
		try {
			let res = await mvApi.get("/payment/passes");
			setApiKey(res.data.api_key);
			let subscriptions = res.data.products.sort((a: any, b: any) => a.amount - b.amount);
			setProducts(subscriptions);
		} catch (err: any) {
			dispatch(
				setErrorData({
					message: "An unexpected error occurred",
				})
			);
			dispatch(setCurrentModal("error"));
		} finally {
			setSubscriptionsLoading(false);
		}
	};

	const getPassExpiration = () => {
		const exp = format(add(new Date(), { days: Number(selectedSubscription!.passLength) }), "MMMM dd, yyyy");
		return exp;
	};

	const options: StripeElementsOptions = {
		clientSecret,
		appearance: {
			theme: "flat",
			rules: {
				".Label": { color: "#fff" },
			},
		},
	};

	return (
		<div className={styles.formWrapper}>
			{paymentSucceeded ? (
				<PaymentConfirmation
					selectedSubscription={products?.find((i: any) => i.passID === selectedSubscription!.passID)}
				/>
			) : (
				<>
					<h3 className={styles.subscriptionSelectTitle}>PICK YOUR PASS</h3>

					<SubscriptionSelect
						subscriptions={products}
						selectedValue={selectedSubscription}
						setSubscription={(sub: any) => {
							setSelectedSubscription(sub);
						}}
						loading={subscriptionsLoading}
					/>

					{selectedSubscription && (
						<p className={styles.subscriptionMessage}>
							<span>EXPIRATION DATE: </span>
							{getPassExpiration()}
						</p>
					)}
					<div className={`${styles.accordion} ${openAccordion === 1 ? styles.open : ""}`}>
						<div className={styles.accordionHeader}>
							<img src={DownCarot} />
							TWO QUICK QUESTIONS
						</div>
						<div className={styles.accordionContent}>
							<SurveyQuestions
								onChange={setSurveyAnswers}
								surveyErrors
								submit={submitSurvey}
								isLoading={submitSurveyLoading}
							/>
						</div>
					</div>
					<div className={`${styles.accordion} ${openAccordion === 2 ? styles.open : ""}`}>
						<div className={styles.accordionHeader}>
							<img src={DownCarot} />
							CREDIT / DEBIT CARD
						</div>
						<div className={styles.accordionContent}>
							<div className={styles.paymentForm}>
								{payIntentLoading || !clientSecret ? (
									<div className={styles.loadingContainer}>
										<Spinner color="0, 93, 164" />
									</div>
								) : (
									<Elements options={options} stripe={stripePromise}>
										<PaymentForm
											selectedSubscription={selectedSubscription}
											setPaymentSucceeded={() => setPaymentSucceeded(true)}
											clientSecret={clientSecret}
										/>
									</Elements>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
