import DatePicker from "react-datepicker";
import styles from "./Filters.module.css";
import DeleteBtn from "../../../../img/X-symbol-black.svg";
import * as moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const DateFilter = ({ onChange, dates }) => {
	const setNewDates = (startDate, endDate) => {
		// Round startDate to the beginning of the day and endDate to the end of day
		let newDates = {
			startDate: dates.startDate,
			endDate: dates.endDate,
		};

		if (startDate !== null) {
			newDates.startDate = moment(startDate).startOf("day")._d;
		} else if (startDate === null) {
			newDates.startDate = null;
		}
		if (endDate !== null) {
			newDates.endDate = moment(endDate).endOf("day")._d;
		} else if (endDate === null) {
			newDates.endDate = null;
		}

		onChange(newDates);
	};

	const removeStartDate = (e) => {
		e.preventDefault();
		setNewDates(null, dates.endDate);
	};
	const removeEndDate = (e) => {
		e.preventDefault();
		setNewDates(dates.startDate, null);
	};

	return (
		<div className={styles.dateFilter}>
			<span className={styles.filterHeader}>REFINE BY DATE</span>
			<span className={styles.dateInputLabel}>START DATE</span>
			<div className={styles.datePickerRow}>
				<DatePicker selected={dates.startDate} onChange={(newStartDate) => setNewDates(newStartDate, dates.endDate)} />
				<button onClick={(e) => removeStartDate(e)}>
					<img src={DeleteBtn} />
				</button>
			</div>
			<span className={styles.dateInputLabel}>END DATE</span>
			<div className={styles.datePickerRow}>
				<DatePicker selected={dates.endDate} onChange={(newEndDate) => setNewDates(dates.startDate, newEndDate)} />
				<button onClick={(e) => removeEndDate(e)}>
					<img src={DeleteBtn} />
				</button>
			</div>
		</div>
	);
};

export default DateFilter;
