import React, { useState } from "react";
import styles from "./TagActionsModal.module.css";
type Props = {
	tabSelected: string;
	onChange: (tab: string) => void;
};
export default function TabSelector({ tabSelected, onChange }: Props) {
	return (
		<nav className={styles.tabSelector}>
			<ul>
				<li
					style={tabSelected === "clip" ? { borderBottom: "4px solid #6abae3" } : { opacity: 0.75 }}
					onClick={() => onChange("clip")}
				>
					CREATE CLIP
				</li>

				<li
					style={tabSelected === "share" ? { borderBottom: "4px solid #6abae3" } : { opacity: 0.75 }}
					onClick={() => onChange("share")}
				>
					SHARE TAG
				</li>
			</ul>
		</nav>
	);
}
