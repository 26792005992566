import { useState } from "react";
import styles from "./TagFilters.module.css";
import TrashCanSolid from "img/TrashCanSolid";

export default function TagToFilterPill({ tag, remove }: any) {
	const [isHovering, setIsHovering] = useState(false);

	return (
		<button
			onMouseOver={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			onClick={() => {
				remove(tag);
			}}
			className={`${styles.tagButton}`}
		>
			#{tag}
			{isHovering && (
				<div className={styles.tagBtnRemoveState}>
					<div style={{ width: "13px" }}>
						<TrashCanSolid color="white" />
					</div>
				</div>
			)}
		</button>
	);
}
