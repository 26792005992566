import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
	children: ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
	const location = useLocation();

	useEffect(() => {
		if (
			location.pathname.includes("broadcast") ||
			location.pathname.includes("facility") ||
			location.pathname.includes("search")
		) {
			window.scrollTo(0, 0);
		}
	}, [location.pathname]); // Only re-run the effect if pathname changes

	return <>{children}</>;
};

export default ScrollToTop;
