// (\__/)
// (='.'=) QUACK
// (")_(")

import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-mux";
import styles from "../Broadcast.module.css";
import TagSidebar from "./tagSidebar";
import TagIconWhite from "../../../../img/icon-tag-white.svg";
import "../video.css";
import FullscreenIcon from "../../../../img/expand-solid.svg";

import Player from "video.js/dist/types/player";
import useAuth from "contexts/auth.context";
import arbiterApi from "api/arbiter/axiosConfig";
import { TagDefinition, TagInstance } from "types/tagging";
import TagActionsModal from "./tagActionsModal";
interface ExtendedPlayer extends Player {
	controlBar?: any;
}

export default function VideoPlayer({ src, broadcastData, start }: any) {
	const { user } = useAuth();
	const videoRef = useRef<any>();
	const playerRef = useRef<ExtendedPlayer>();
	const videoWrapperRef = useRef<any>();
	const [tagModalOpen, setTagModalOpen] = useState(false);
	const [privateTimeLineTags, setPrivateTimeLineTags] = useState([]);
	const [publicTimeLineTags, setPublicTimeLineTags] = useState([]);
	const [tagsToAdd, setTagsToAdd] = useState<TagInstance[]>([]);
	const [tagsToSave, setTagsToSave] = useState<TagInstance[]>([]);
	const [addingTagInstance, setAddingTagInstance] = useState(false);
	const [tagActionsModal, setTagActionsModal] = useState<TagInstance>();

	// const [isFullScreen, setFullScreen] = useState(false);

	const [newTagAdded, setNewTagAdded] = useState(false);

	let keepControlsOpen: any = null;

	useEffect(() => {
		// This event listener is necessary to detect when full screen is exit due to key press (ex. escape key)

		// document.addEventListener("fullscreenchange", function () {
		// 	setFullScreen(document.fullscreenElement);
		// });

		return function () {
			document.removeEventListener("fullscreenchange", () => {});
		};
	}, []);

	const toggleFullScreen = () => {
		if (!videoWrapperRef.current) return;

		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		} else {
			let elem: any = videoWrapperRef.current;

			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			}
		}
	};

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = videoRef.current;

			if (!videoElement) return;

			const video = (playerRef.current = videojs(videoElement, {
				sources: [{ src, type: "application/x-mpegURL" }],

				autoplay: true,

				controls: true,

				responsive: true,

				controlBar: {
					fullscreenToggle: false,
				},

				fluid: true,

				plugins: {
					mux: {
						debug: false,

						data: {
							env_key: "s3lebqhfr5ine2bl51t8ht9vq",

							player_name: "WEB",

							player_init_time: Date.now(),

							viewer_user_id: user!.userID,

							sub_property_id: broadcastData.site_id,

							player_version: "1.0.0",

							video_id: broadcastData.id,

							video_title: broadcastData.title,

							// video_series: broadcastData.field_id,

							video_duration: broadcastData.expected_duration,

							video_stream_type: broadcastData.status,
						},
					},
				},
			}));

			video.ready(() => {
				video.on("loadedmetadata", () => {
					getTagInstances();

					let player = playerRef.current;

					player?.currentTime(start);

					var myButton = player?.controlBar?.addChild(
						"button",

						{
							clickHandler: () => toggleFullScreen(),
						},

						9
					);

					var myButtonDom = myButton.el();

					myButtonDom.innerHTML = `<img



                        class="fullscreenToggle"



                            alt="Fullscreen Toggle Button"



                            src=${FullscreenIcon}



                        />`;
				});
			});
		}

		return function () {
			if (playerRef.current != null) {
				playerRef.current.off("loadedmetadata", () => null);

				playerRef.current.off("ready", () => null);
			}
		};
	}, [videoRef]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player) {
				player.dispose();

				playerRef.current = undefined;
			}
		};
	}, [playerRef]);

	useEffect(() => {
		mapTagsToTimeLine([...privateTimeLineTags, ...publicTimeLineTags]);
	}, [privateTimeLineTags, publicTimeLineTags]);

	useEffect(() => {
		if (tagsToAdd.length === 0) return;

		if (playerRef.current === null) return;

		if (tagsToAdd.length > 1) {
			addTag(tagsToAdd);

			return;
		}

		if (tagsToAdd.length === 1) {
			addTag(tagsToAdd);

			keepControlsOpen = setInterval(() => {
				playerRef.current?.userActive(true);
			}, 100);
		}

		return () => {
			clearInterval(keepControlsOpen);
		};
	}, [tagsToAdd]);

	const getTagInstances = async () => {
		try {
			const publicInstances = await arbiterApi.get(`/tags?visibility=public&broadcast_id=${broadcastData.id}`);
			const privateInstances = await arbiterApi.get(
				`/tags?visibility=private&broadcast_id=${broadcastData.id}&user_id=${user?.userID}`
			);
			setPublicTimeLineTags(publicInstances.data.results);
			setPrivateTimeLineTags(privateInstances.data.results);
		} catch (err: any) {
			// TODO
			console.error(err);
		}
	};
	const removeTagInstance = async (id: string) => {
		try {
			await arbiterApi.delete(`/tags?tag_id=${id}`);
			getTagInstances();
		} catch (err: any) {
			// TODO
			console.error(err);
		}
	};
	const mapTagsToTimeLine = (tags: any) => {
		console.log(tags);
		let controlBar = playerRef?.current?.controlBar.children_.filter((e: any) => e.name_ == "ProgressControl");

		if (controlBar && controlBar[0] && controlBar[0].seekBar && controlBar[0].seekBar.el()) {
			// Select all existing tagContainer elements
			const existingTagContainers = controlBar[0].seekBar.el().querySelectorAll(".tagContainer");
			// Remove each found tagContainer element
			existingTagContainers.forEach((container: any) => {
				// Assuming container is a DOM element and can be removed directly
				container.remove();
			});
		}

		tags.forEach((tag: TagInstance) => {
			let tagContainer = controlBar[0].seekBar.addChild("button");

			tagContainer.addClass("tagContainer");

			if (tag.visibility === "public") {
				tagContainer.addClass("public");
			} else if (tag.visibility === "private") {
				tagContainer.addClass("private");
			}

			tagContainer.setAttribute("data-label", tag.name);
			tagContainer.el().addEventListener("click", (event: any) => {
				event.stopPropagation(); // Prevent event from bubbling up to parent elements
				setTagActionsModal(tag);
			});
			if (tag.visibility === "private") {
				let removeTagBtn = tagContainer.addChild("button");
				removeTagBtn.addClass("removeTagBtn"); // Add a class for easier CSS targeting

				// Add your event listener
				removeTagBtn.el().addEventListener("click", (event: any) => {
					event.stopPropagation(); // Prevent event from bubbling up to parent elements

					tagContainer.el().remove();
					removeTagInstance(tag.tag_id!);
					// You can also call a function here to handle the removal logic
				});
				removeTagBtn.el().innerHTML = "x";
			}

			let placement;
			if (playerRef?.current) {
				const duration = playerRef.current.duration();
				if (duration) {
					placement = (tag.timestamp * 100) / duration;
				} else {
					// Handle the case where duration is 0, null, or undefined
				}
			} else {
				// Handle the case where playerRef.current is undefined
			}

			tagContainer.el().style.left = placement + "%";
		});
	};

	const addTag = (tags: TagInstance[]) => {
		// remove existing tag if any

		const elements = document.getElementsByClassName("tagToEdit") as HTMLCollectionOf<Element>;

		while (elements.length > 0) {
			let element = elements[0];
			element.parentNode?.removeChild(element);
		}

		let controlBar = playerRef?.current?.controlBar.children_.filter((e: any) => e.name_ == "ProgressControl");

		// change control bar styling

		playerRef?.current?.controlBar.dimension("height", 50);

		controlBar[0].seekBar.dimension("height", 35);

		controlBar[0].seekBar.addClass("seekBarBig");

		controlBar[0].seekBar.getChild("PlayProgressBar").hide();

		// Create tag component and render it to the DOM

		let tempButton = controlBar[0].seekBar.addChild("button");

		tempButton.addClass("tagToEdit");

		let tagName = tags[0].name;

		tags.forEach((item: any, i: number) => {
			if (i === 0) return;

			tagName = tagName + " + " + item.name;
		});

		tempButton.setAttribute("data-label", tagName);

		tempButton.setAttribute("draggable", "true");

		let placement;
		if (playerRef?.current) {
			const duration = playerRef.current.duration();
			if (duration) {
				placement = (tags[0].timestamp * 100) / duration;
			} else {
				// Handle the case where duration is 0, null, or undefined
			}
		} else {
			// Handle the case where playerRef.current is undefined
		}

		tempButton.el().style.left = placement + "%";

		// Tag events

		let handleMouseOver = () => {
			controlBar[0].seekBar.getChild("MouseTimeDisplay").hide();

			controlBar[0].seekBar

				.getChild("PlayProgressBar")

				.getChild("TimeTooltip")

				.hide();

			// controlBar[0].disable();
		};

		let handleMouseLeave = () => {
			controlBar[0].seekBar.getChild("MouseTimeDisplay").show();

			controlBar[0].seekBar

				.getChild("PlayProgressBar")

				.getChild("TimeTooltip")

				.show();

			controlBar[0].enable();
		};

		let repositionTag = (e: any) => {
			let newPlacement;

			if (playerRef.current && typeof playerRef.current.currentTime === "function") {
				const currentTime = playerRef.current.currentTime();
				const duration =
					playerRef.current.duration && typeof playerRef.current.duration === "function"
						? playerRef.current.duration()
						: 0;

				if (currentTime !== undefined && duration) {
					newPlacement = (currentTime * 100) / duration;
				} else {
					// Handle cases where currentTime is undefined or duration is 0, undefined, or non-valid
				}
			} else {
				// Handle the case where playerRef.current is undefined
			}
			tempButton.el().style.left = newPlacement + "%";
			let tagsNewTimestamps = tagsToSave.map((tag) => ({
				...tag,
				timestamp: playerRef?.current?.currentTime() ?? 0,
			}));
			setTagsToSave(tagsNewTimestamps);
		};

		controlBar[0].seekBar.on("mouseup", repositionTag);

		tempButton.on("mouseover", handleMouseOver);

		tempButton.on("mouseleave", handleMouseLeave);
	};

	let exitAddTagMode = () => {
		let controlBar = playerRef?.current?.controlBar.children_.filter((e: any) => e.name_ == "ProgressControl");

		// change styling back to default

		controlBar[0].seekBar.getChild("PlayProgressBar").show();

		playerRef?.current?.controlBar.dimension("height", 30);

		controlBar[0].seekBar.removeClass("seekBarBig");

		controlBar[0].seekBar.dimension("height", 7);

		// remove tag

		const elements = document.getElementsByClassName("tagToEdit") as HTMLCollectionOf<Element>;

		while (elements.length > 0) {
			let element = elements[0];
			element.parentNode?.removeChild(element);
		}

		controlBar[0].seekBar.off("mouseup");
	};

	let tagClicked = (tagData: TagDefinition) => {
		playerRef?.current?.userActive(true);

		let newTag = {
			broadcast_id: broadcastData.id,
			user_id: user!.userID,
			name: tagData.name,
			timestamp: playerRef?.current?.currentTime() ?? 0,
			visibility: tagData.visibility,
		};

		setTagsToAdd([...tagsToAdd, newTag]);

		setTagsToSave([...tagsToSave, newTag]);
	};

	let saveTagInstances = async () => {
		setAddingTagInstance(true);

		try {
			let res: any = [];
			const requests = tagsToSave.map((tag: TagInstance) => {
				let body = {
					...tag,
					timestamp: tag.timestamp.toString(),
				};
				return arbiterApi.post(`/tags?user_id=${user?.userID}`, body);
			});
			await Promise.all(requests);

			return res;
		} catch (e) {
			console.error(e);
			return;
		} finally {
			onCancel();
			getTagInstances();
			setAddingTagInstance(false);
			exitAddTagMode();
		}
	};

	let onCancel = () => {
		clearInterval(keepControlsOpen);
		exitAddTagMode();
		setTagsToAdd([]);
		setTagsToSave([]);
	};

	return (
		<div className="vidWrapper" ref={videoWrapperRef}>
			<video
				id="video-js-player"
				controls
				autoPlay
				muted
				preload="auto"
				ref={videoRef}
				style={{ width: "100%", height: "100%" }}
				className="video-js vjs-big-play-centered"
			></video>
			<div className={styles.videoOverlayContainer}>
				<button title="Video tagging" className={styles.videoOverlayBtn} onClick={() => setTagModalOpen(true)}>
					<img height="20px" src={TagIconWhite} alt="Tag Icon" />
				</button>
			</div>

			<TagSidebar
				isOpen={tagModalOpen}
				closeModal={() => {
					onCancel();
					setTagModalOpen(false);
				}}
				tagClicked={(tagData: any) => tagClicked(tagData)}
				tagsSelected={tagsToAdd}
				onApply={saveTagInstances}
				onCancel={onCancel}
				broadcastData={broadcastData}
				addingTagInstance={addingTagInstance}
				reloadData={newTagAdded}
				setDataReloaded={() => setNewTagAdded(false)}
			/>
			{tagActionsModal && <TagActionsModal tag={tagActionsModal} close={() => setTagActionsModal(undefined)} />}
		</div>
	);
}
