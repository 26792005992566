import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import styles from "./Overview.module.css";
import ReactGA from "react-ga4";

import TagFilters from "./tagFilters/TagFilters";
import TagInstancesList from "./TagInstancesList";
import TagIconWhite from "../../../../img/icon-tag-white.svg";
import { TagDefinition } from "types/tagging";

const Overview = () => {
	const [tagsToFilter, setTagsToFilter] = useState<TagDefinition[]>([]);

	// Load tags from local storage when the component mounts
	useEffect(() => {
		ReactGA.send({ hitType: "page", page: window.location.pathname, title: "My Tags" });

		const storedTags = localStorage.getItem("tagsToFilter");
		if (storedTags) {
			setTagsToFilter(JSON.parse(storedTags));
		}
	}, []);

	const handleFilterChange = (tags: TagDefinition[]) => {
		setTagsToFilter(tags);
		// Save the updated tags to local storage
		localStorage.setItem("tagsToFilter", JSON.stringify(tags));
	};

	return (
		<div className={styles.overview}>
			<Helmet>
				<title>MuscoVision - My Tags</title>
				<meta name="description" content="Musco Vision" />
			</Helmet>
			<section
				className={styles.content}
				style={{
					textAlign: "left",
					paddingTop: "75px",
				}}
			>
				<h3
					style={{
						marginBottom: "1rem",
						display: "flex",
						alignItems: "center",
						color: "white",
					}}
				>
					<div className={styles.tagIcon}>
						<img height="12px" src={TagIconWhite} alt="Tag Icon" />
					</div>
					TAGGED CONTENT
				</h3>
				<hr />
				<TagFilters setTagsToFilter={(tags: any) => handleFilterChange(tags)} tagsToFilter={tagsToFilter} />
				<TagInstancesList tagsToFilter={tagsToFilter} />
			</section>
		</div>
	);
};

export default Overview;
