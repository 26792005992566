import styles from "./AccountDetails.module.css";

import { useNavigate } from "react-router";
import Email from "./components/Email";
import Password from "./components/Password";
import Subscription from "./components/Subscription";
import useAuth from "contexts/auth.context";

export default function Profile() {
	const { user, refresh } = useAuth();
	console.log(user);
	return (
		<div className={styles.accountDetails}>
			<Email user={user} refreshData={() => refresh()} />
			<Password user={user} refreshData={() => refresh()} />
			<Subscription user={user} refreshData={() => refresh()} />
		</div>
	);
}
