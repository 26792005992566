import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import GetBroadcasts from "../../../components/data/getBroadcasts/GetBroadcasts";
import VideoPlayer from "./videoPlayer";
import { Helmet } from "react-helmet";
import styles from "./Broadcast.module.css";
import Spinner from "../../../components/general/spinner/Spinner";
import ReactGA from "react-ga4";
import AccessPrompt from "./accessPrompt/AccessPrompt";
import Moment from "react-moment";
import Info from "./info/Info";
import { BroadcastObject } from "types/broadcast";
import { useLocation, useParams } from "react-router-dom";
import useAuth from "contexts/auth.context";
import mvApi from "api/general/axiosConfig";
import arbiterApi from "api/arbiter/axiosConfig";
import Cookies from "js-cookie";
import { ACCESS_TOKEN, ID_TOKEN } from "constants/auth";

function Broadcast() {
	const params = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const start = queryParams.get("start");
	const awaitingPassUpdate = location.state?.awaitingPassUpdate;
	const { user, isAuthenticated } = useAuth();
	const select = useRef(null);
	const [error, setError] = useState<string>(null!);
	const [playerError, setPlayerError] = useState<string>(null!);
	const [startTime, setStartTime] = useState<string>(null!);
	const [tabSelected, setTabSelected] = useState<"streaming" | "scheduled" | "archived">("streaming");
	const [vidSrc, setVidSrc] = useState<string>();
	const [displayAccessPrompt, setDisplayAccessPrompt] = useState({
		display: false,
		message: "",
	});
	const [isLoading, setIsLoading] = useState(true);
	const [broadcastData, setBroadcastData] = useState<BroadcastObject>();
	const broadcastId = params.broadcastId;
	const accessToken = Cookies.get(ACCESS_TOKEN);
	const idToken = Cookies.get(ID_TOKEN);

	useEffect(() => {
		// ReactGA.send({ hitType: "page", page: window.location.pathname, title: "Broadcast" });
		getBroadcast();
	}, []);

	useEffect(() => {
		checkAccessPass();
	}, [broadcastData]);

	let checkBroadcastStartLoop = () => {
		// Begin a loop that waits for the broadcasts scheduled start time to occur.
		if (broadcastData) {
			let loop = setInterval(() => {
				let broadcastStart = new Date(broadcastData.date);

				let now = new Date();

				if (broadcastStart <= now) {
					clearInterval(loop);

					setTimeout(() => {
						setStartTime(null!);

						getBroadcast();
					}, 15000);
				}
			}, 2000);
		}
	};

	const checkAccessPass = async () => {
		if (broadcastData) {
			if (isAuthenticated) {
				if (broadcastData.revenue_model.toLowerCase() === "free") {
					setDisplayAccessPrompt({
						display: false,
						message: "",
					});

					if (broadcastData.status === "scheduled") {
						setStartTime(broadcastData.date);
						checkBroadcastStartLoop();
					} else {
						generateMuxToken();
					}

					setIsLoading(false);
				} else {
					const passExpiration = new Date(user!.subscription.pass.passEnd ?? 0);

					if (passExpiration > new Date() || awaitingPassUpdate) {
						setDisplayAccessPrompt({ display: false, message: "" });

						if (broadcastData.status === "scheduled") {
							setStartTime(broadcastData.date);

							checkBroadcastStartLoop();
						} else {
							// for live and archived broadcasts
							generateMuxToken();
						}
					} else {
						setDisplayAccessPrompt({
							display: true,
							message: "No valid pass",
						});
					}
				}
			} else {
				setDisplayAccessPrompt({
					display: true,
					message: "User not logged in",
				});
			}
		} else {
			// TODO: throw an error here
		}
	};

	const generateMuxToken = async () => {
		let tokenRetryCount = 0;

		const getToken = async () => {
			const body = {
				broadcast: broadcastData,
				token: accessToken,
			};
			try {
				const res: any = await mvApi.post(`/viewer/token`, body, {
					headers: {
						Authorization: idToken,
					},
				});
				// setVidSrc(res.data.url);

				// FIX FOR EDGE CASE: When user pays for pass while on this page.

				// - Takes a bit for pass to update in database.

				// - Keep calling this until valid token is returned.

				// - Throw error after retry limit is reached
				console.log(res.data);
				if (res.data.success === "false") {
					if (res.data.error === "INACTIVE_PASS") {
						if (tokenRetryCount < 5) {
							setTimeout(() => {
								tokenRetryCount = tokenRetryCount + 1;

								getToken();
							}, 1000);
						} else {
							console.log(1);
							setError("An error occurred. Please try again later.");
							setIsLoading(false);
						}
					} else {
						console.log(2);
						setError("An error occurred. Please try again later.");
						setIsLoading(false);
					}
				} else {
					if (res.data.res.url) {
						setVidSrc(res.data.res.url);
						setIsLoading(false);
					} else {
						console.log(3);
						setError("An error occurred. Please try again later.");
						setIsLoading(false);
					}
				}
			} catch (err: any) {
				console.log(err);
				setError("An error occurred. Please try again later.");
				setIsLoading(false);
			}
		};

		getToken();
	};
	const getBroadcast = () => {
		setError(null!);

		const fetchBroadcast = async () => {
			try {
				const res = await arbiterApi.get(`/broadcast?id=${broadcastId}`);
				console.log(res.data[0]);
				if (res.data.length > 0) {
					setBroadcastData(res.data[0]);
				} else {
					throw new Error();
				}
			} catch (err: any) {
				setError("An error occurred. Please try again later.");
			} finally {
				setIsLoading(false);
			}
		};

		fetchBroadcast();
	};

	const onSelectChange = (e: any) => {
		let selectedOption = e.target.value;

		switch (selectedOption) {
			case "streaming":
				setTabSelected("streaming");

				break;

			case "scheduled":
				setTabSelected("scheduled");

				break;

			case "archived":
				setTabSelected("archived");

				break;

			default:
				setTabSelected("streaming");
		}
	};

	return (
		<div className={styles.broadcast}>
			<Helmet>
				<title>MuscoVision</title>
				<meta name="description" content="Broadcast" />
			</Helmet>

			<section className={styles.broadcastVideo}>
				<div className={styles.videoFrame}>
					<div className={styles.playerWrapper}>
						{!isLoading ? (
							<>
								{displayAccessPrompt.display ? (
									<AccessPrompt message={displayAccessPrompt.message} />
								) : (
									<>
										{error !== null ? (
											<div className={styles.errorScreen}>
												<div className={styles.overlay}>
													<p>{error}</p>
												</div>
											</div>
										) : (
											<>
												{startTime ? (
													<div className={styles.countDownScreen}>
														<p>
															This broadcast is scheduled to start on <Moment format="MMMM DD" date={startTime} /> at{" "}
															<Moment format="hh:mm A" date={startTime} />
														</p>
													</div>
												) : null}

												{vidSrc ? (
													<>
														<VideoPlayer
															src={vidSrc}
															broadcastData={broadcastData}
															setPlayerError={() => {
																setPlayerError("An error occurred. Please try again later.");
															}}
															start={start}
														/>

														{playerError ? (
															<div className={styles.errorScreen}>
																<div className={styles.overlay}>
																	<p>{playerError}</p>
																</div>
															</div>
														) : null}
													</>
												) : null}
											</>
										)}
									</>
								)}
							</>
						) : (
							<div className={styles.loadingScreen}>
								<Spinner color="255, 255, 255" />
							</div>
						)}
					</div>
				</div>
			</section>

			<section className={styles.bottomContent}>
				<Info broadcastData={broadcastData} isLoading={isLoading || !broadcastData} />

				<section className={styles.broadcastNav}>
					<nav>
						<ul>
							<li
								style={tabSelected === "streaming" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
								onClick={() => setTabSelected("streaming")}
							>
								LIVE
							</li>

							<li
								style={tabSelected === "scheduled" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
								onClick={() => setTabSelected("scheduled")}
							>
								UPCOMING
							</li>

							<li
								style={tabSelected === "archived" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
								onClick={() => setTabSelected("archived")}
							>
								RECENT
							</li>
						</ul>
					</nav>
				</section>

				<section className={styles.broadcastNavMobile}>
					<select onChange={(e) => onSelectChange(e)} ref={select}>
						<option
							value={"streaming"}
							style={tabSelected === "streaming" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
						>
							LIVE
						</option>

						<option
							value={"scheduled"}
							style={tabSelected === "scheduled" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
						>
							UPCOMING
						</option>

						<option
							value={"archived"}
							style={tabSelected === "archived" ? { borderBottom: "4px solid rgb(0, 94, 164)" } : {}}
						>
							RECENT
						</option>
					</select>
				</section>
			</section>

			<section className={styles.expandableList}>
				<GetBroadcasts
					filters={null}
					facilityID={broadcastData ? broadcastData.site_id : ""}
					hideTitle
					updateParent={() => null}
					isLoading={isLoading || broadcastData === null}
					viewerStatus={tabSelected}
				/>
			</section>
		</div>
	);
}

export default Broadcast;
