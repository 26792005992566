import { useState, useEffect } from "react";
import styles from "./Auth.module.css";
import { useDispatch } from "react-redux";
import { setCurrentModal, setLoading } from "../../../redux/actions/modal";

import ReactGA from "react-ga4";
import PasswordChecklist from "react-password-checklist";
import logo from "../../../img/MuscoVision_logo-blue-grey.svg";
import { FormEvent } from "react";
import { signUp } from "aws-amplify/auth";
import ConfirmAccount from "./ConfirmAccount";

type Errors = {
	name: string;
	email: string;
	password: string;
	confirmPassword: string;
};

const Register = () => {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState<string>(null!);
	const [renderConfirmationForm, setRenderConfirmationForm] = useState<boolean>(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [fieldErrors, setFieldErrors] = useState<Errors>({
		name: null!,
		email: null!,
		password: null!,
		confirmPassword: null!,
	});

	useEffect(() => {
		ReactGA.event({
			category: "Modal",
			action: "Register Modal",
		});
	}, []);

	const handleSignUp = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setFieldErrors({
			name: null!,
			email: null!,
			password: null!,
			confirmPassword: null!,
		});
		setErrorMessage(null!);

		let res = validateFields();
		if (res.success) {
			dispatch(setLoading({ isLoading: true, message: "Registering..." }));
			signup();
		} else {
			setFieldErrors(res.errors);
		}
	};
	const validateFields = () => {
		let errors: Errors = {
			name: null!,
			email: null!,
			password: null!,
			confirmPassword: null!,
		};
		let errorFound = false;
		if (name === "") {
			errorFound = true;
			errors.name = "Please enter your full name";
		}
		if (email === "") {
			errorFound = true;
			errors.email = "Please enter your email address";
		} else {
			// Validate the email with a regex.
			// Verifies that the text is proper email format
			const res =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!res.test(String(email).toLowerCase())) {
				errorFound = true;
				errors.email = "Email is invalid";
			}
		}

		if (password === "") {
			errorFound = true;
			errors.password = "Please create your password";
		} else if (password.length < 10) {
			errors.password = "Password must be at least 10 characters";
		}
		if (confirmPassword === "") {
			errorFound = true;
			errors.confirmPassword = "Please confirm your password";
		} else if (confirmPassword !== password) {
			errorFound = true;
			errors.confirmPassword = "Passwords do not match";
		}

		if (!errorFound) {
			return {
				success: true,
				errors: null!,
			};
		} else {
			return {
				success: false,
				errors: errors,
			};
		}
	};
	const signup = async () => {
		// const d = new Date();
		// let text = d.toUTCString();
		await signUp({
			username: email,
			password: password,
			options: {
				userAttributes: {
					name: name,
					updated_at: new Date().getTime().toString(),
					"custom:userType": "null",
					"custom:passStart": new Date().toString(),
					"custom:passEnd": new Date().toString(),
				},
				autoSignIn: true,
			},
		})
			.then((res: any) => {
				dispatch(setLoading({ isLoading: false, message: "" }));
				setRenderConfirmationForm(true);
			})
			.catch((err: any) => {
				dispatch(setLoading({ isLoading: false, message: "" }));

				switch (err.name) {
					case "UsernameExistsException":
						setErrorMessage("There is already a user with this email.");
						break;
					case "AuthError":
						if (err.log) {
							if (err.log === "Username cannot be empty") {
								setErrorMessage("Email cannot be empty");
							} else {
								setErrorMessage(err.log);
							}
						}

						break;
					default:
						setErrorMessage("Registration unsuccessful.");
				}
			});
	};

	return (
		<>
			{renderConfirmationForm ? (
				<ConfirmAccount email={email} password={password} backToLogin={() => dispatch(setCurrentModal("login"))} />
			) : (
				<div className={styles.formWrapper}>
					<img alt="logo" style={{ marginBottom: "3rem" }} src={logo} />
					<form onSubmit={handleSignUp}>
						<h3
							style={{
								color: "black",
								marginBottom: "1.5rem",
								textAlign: "center",
							}}
						>
							CREATE YOUR ACCOUNT
						</h3>
						{errorMessage ? <span className={styles.errorMessage}>{errorMessage}</span> : null}

						<div className={styles.inputGroup}>
							<label className={styles.label}>FULL NAME</label>
							<input autoFocus type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="NAME" />
							{fieldErrors.name !== null ? <span className={styles.errorMessage}>{fieldErrors.name}</span> : null}
						</div>
						<div className={styles.inputGroup}>
							<label className={styles.label}>EMAIL</label>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="EMAIL ADDRESS"
							/>
							{fieldErrors.email !== null ? <span className={styles.errorMessage}>{fieldErrors.email}</span> : null}
						</div>

						<div className={styles.inputGroup}>
							<label className={styles.label}>CREATE PASSWORD</label>
							<input
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								type="password"
								placeholder="PASSWORD (CASE SENSITIVE)"
							/>
							{fieldErrors.password !== null ? (
								<span className={styles.errorMessage}>{fieldErrors.password}</span>
							) : null}
						</div>

						<div className={styles.inputGroup}>
							<label className={styles.label}>CONFIRM PASSWORD</label>
							<input
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								type="password"
								placeholder="PASSWORD (CASE SENSITIVE)"
							/>
							{fieldErrors.confirmPassword !== null ? (
								<span className={styles.errorMessage}>{fieldErrors.confirmPassword}</span>
							) : null}
						</div>
						{password.length > 0 && (
							<>
								<span className={styles.passwordRequirementsLabel}>Password Requirements:</span>

								<PasswordChecklist
									className={styles.passwordChecklist}
									rules={["minLength", "specialChar", "number", "lowercase", "capital", "match"]}
									minLength={10}
									value={password}
									valueAgain={confirmPassword}
									validColor="#4BCA81"
									invalidColor="#df6f69"
								/>
							</>
						)}
						<button className={styles.submitBtn} type="submit">
							CONTINUE
						</button>
					</form>

					<p style={{ color: "black", marginTop: "1rem" }}>
						Already have an account?{" "}
						<span
							onClick={() => dispatch(setCurrentModal("login"))}
							style={{ color: "rgb(0, 93, 164)", cursor: "pointer" }}
						>
							Log in here
						</span>
					</p>
					<p style={{ color: "grey", marginTop: "3rem", fontSize: 13 }}>
						By clicking "CONTINUE" you agree to the MuscoVision{" "}
						<a href="/docs/terms_of_use.pdf" target="_blank" style={{ color: "rgb(0, 93, 164)", cursor: "pointer" }}>
							Terms of Use
						</a>{" "}
						and{" "}
						<a
							href="/docs/online_privacy_policy.pdf"
							target="_blank"
							style={{ color: "rgb(0, 93, 164)", cursor: "pointer" }}
						>
							Privacy Policy
						</a>
					</p>
				</div>
			)}
		</>
	);
};
export default Register;
