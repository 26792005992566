import styles from "./Overview.module.css";

import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

export default function TagInstanceTile({ broadcast }: any) {
	const renderTags = broadcast.tagInstances.map((item: any) => {
		return (
			<Link className={`${styles.tag} ${styles.active}`} to={`/broadcast/${item.broadcast_id}?start=${item.timestamp}`}>
				<span>#{item.name}</span>
			</Link>
		);
	});

	return (
		<div className={styles.tagTile}>
			<div className={styles.tagTileImg} style={{ backgroundImage: `url(${broadcast.medium_image})` }}></div>
			<h6 className={styles.title}>{broadcast.title}</h6>
			<div className={styles.tagListWrapper}>{renderTags}</div>
		</div>
	);
}
